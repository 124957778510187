import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from 'src/store';
import http from 'src/utils/http';
import {NotificationType} from "../models/user";


interface NotificationsState {

    notifications: any[];
    appointmentNotifications: any[]
    messagesNotification: any[]

}


const initialState: NotificationsState = {
    notifications: [],
    appointmentNotifications: [],
    messagesNotification: []
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        getNotifications(state: NotificationsState, action: PayloadAction<any>) {
            const notifications = action.payload;
            if (notifications) {
                state.notifications = notifications
            } else {
                state.notifications = null
            }
        },
        unreadNotificationsAppointment(state: NotificationsState, action: PayloadAction<{ notifications?: any[], notification?: any }>) {
            const {notifications, notification} = action.payload;
            if (notifications) {
                state.appointmentNotifications = notifications
            } else if (notification) {
                state.appointmentNotifications = [...state.appointmentNotifications, {
                    id: notification.id,
                    created: notification.created,
                    creator: notification.creator.id,
                    personCreator: notification.creator.person,
                    body: notification.body,
                    appointment: notification.appointment.id
                }]
            }
        },
        unreadNotificationsMessenger(state: NotificationsState, action: PayloadAction<any>) {
            const {notifications, notification} = action.payload;
            if (notifications) {
                state.messagesNotification = notifications
            } else if (notification) {
                state.messagesNotification = [...state.messagesNotification,
                    {
                        id: notification.id,
                        created: notification.created,
                        creator: notification.creator.id,
                        personCreator: notification.creator.person,
                        body: notification.body,
                        edited: notification.edited,
                        type: notification.type,
                        toUser: notification.toUser.id,
                        read: notification.read,
                    }];
            }
        },
        readNotification(state: NotificationsState, action: PayloadAction<{ creator?: string, type: string, id?: string, appointment?: string }>) {
            const {creator, type, id, appointment} = action.payload;

            // if (state.notifications)
            //     state.notifications = state?.notifications?.map(v => (v.creator == creator ? {...v, read: true} : v));
            if (type == NotificationType.APPOINTMENT && appointment) {
                state.appointmentNotifications = state?.appointmentNotifications?.filter(v => v.appointment !== appointment)
            } else if (type == NotificationType.MESSENGER && creator) {
                state.messagesNotification = state?.messagesNotification?.filter(v => v.creator !== creator)
            }
        },
        resetNotifications(state: NotificationsState, action: PayloadAction) {
            state.notifications = []
        },
        removeUnreadNotificationMessenger(state: NotificationsState, action: PayloadAction<{ idNotification: string }>) {
            state.messagesNotification = state.messagesNotification?.filter(n => n.id != action.payload.idNotification);
        },
        removeUnreadNotificationAppointment(state: NotificationsState, action: PayloadAction<{ idNotification: string }>) {
            state.appointmentNotifications = state.appointmentNotifications?.filter(n => n.id != action.payload.idNotification);
        },
    }
});

export const reducer = slice.reducer;


export const updateUnreadNotifications = (n): AppThunk => (dispatch) => {
    if (n.type == "appointment")
        dispatch(slice.actions.unreadNotificationsAppointment({notification: n}));
    else if (n.type == "messenger")
        dispatch(slice.actions.unreadNotificationsMessenger({notification: n}));
};

export const getNotifications =
    (params): AppThunk =>
        async (dispatch) => {

            dispatch(slice.actions.resetNotifications());
            const notifications = await http.get("/notification", {
                params: params,
            });
            dispatch(slice.actions.getNotifications(notifications.data));
        };

export const readMessageNotification =
    (creator): AppThunk =>
        async (dispatch) => {
            await http.post(`/notification/read/message/user/${creator}`);
            dispatch(slice.actions.readNotification({creator: creator, type: NotificationType.MESSENGER}));
        };

export const readAppointmentNotification = (ids): AppThunk =>
        async (dispatch) => {
            await http.post(`/notification/read/appointment/${ids}`);
            ids.forEach(id => dispatch(slice.actions.readNotification({
                appointment: id,
                type: NotificationType.APPOINTMENT
            })))
};

export const unreadNotifications =
    (): AppThunk =>
        async (dispatch) => {
            try {
                const notifications = await http.get("/notification/unread");
                if (notifications?.data?.length > 0) {
                    dispatch(slice.actions.unreadNotificationsAppointment({notifications: notifications?.data?.filter(v => v.type === 'appointment')}));
                    dispatch(slice.actions.unreadNotificationsMessenger({notifications: notifications?.data?.filter(v => v.type === 'messenger')}));
                } else {
                    dispatch(slice.actions.unreadNotificationsAppointment([]));
                    dispatch(slice.actions.unreadNotificationsMessenger([]));
                }
            } catch (e) {

            }

        };
export const deliveredNotifications = (notificationId: number): AppThunk =>
    async (dispatch) => {
        http.get(`/notification/delivered/${notificationId}`)
    };


export default slice;
