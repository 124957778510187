import { ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { Appointment } from 'src/models/event';

interface CallState {
  type?: 'audio' | 'video';
  caller: string | null;
  callee: string | null;
  room: string | null;
  state: 'new' | 'outgoing' | 'incoming' | 'answered';
  setType: (value: 'audio' | 'video') => void;
  setCaller: (value: string) => void;
  setCallee: (value: string) => void;
  setRoom: (value: string) => void;
  setState: (value: 'new' | 'outgoing' | 'incoming' | 'answered') => void;
  appointment?: Appointment;
  setAppointment: (value: Appointment) => void;
}

const CallContext = createContext<CallState>({
  caller: null,
  callee: null,
  room: null,
  state: 'new',
  setCaller: () => {},
  setCallee: () => {},
  setRoom: () => {},
  setType: () => {},
  setState: () => {},
  setAppointment: () => {}
});

export const useCallContext = () => useContext(CallContext);

export default function CallProvider({ children }: { children: ReactNode }) {
  const [caller, setCaller] = useState<string | null>(null);
  const [callee, setCallee] = useState<string | null>(null);
  const [room, setRoom] = useState<string | null>(null);
  const [appointment, setAppointment] = useState<Appointment>();
  const [type, setType] = useState<'audio' | 'video'>(undefined);
  const [state, setState] = useState<
    'new' | 'outgoing' | 'incoming' | 'answered'
  >('new');

  const value = useMemo(
    () => ({
      caller,
      setCaller,
      callee,
      setCallee,
      room,
      setRoom,
      type,
      setType,
      state,
      setState,
      appointment,
      setAppointment
    }),
    [caller, callee, type, room, state, appointment]
  );

  return <CallContext.Provider value={value}>{children}</CallContext.Provider>;
}
