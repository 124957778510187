const htJSON = {
    'Welcome to SEROvie! Setup your account!': 'Byenvini sou SEROvie! konfigire kont ou.',
    'Fill in the fields below to sign up for an account.': 'Ranpli espas anba yo pou ka kreye yon kont',
    'Already have an account?': 'Ou gen yon kont deja?',
    'Sing in': 'Idantifye w',
    'The givename field is required': 'Espas non an obligatwa',
    'The lastname field is required': 'Espas siyati a obligatwa',
    'The email provided should be a valid email address': 'Imel la dwe valid',
    'The email field is required': 'Espas imel la obligatwa',
    'The name field is required': 'Espas non itilizatè a obligatwa',
    'Password must have minimum 8 characters': 'Modpas la dwe genyen pou pi piti 8 karaktè',
    'Password format is incorrect': 'Fòma modpas la pa bon',
    'Passwords are not identical': 'Modpas yo pa menm',
    'Connect my account with': 'Konekte kont mwen ak ',
    'Sign up': 'Enskri\'m',
    'Given Name': "Siyati",
    'Last Name': 'Non',
    'Username': 'Non itilizatè',
    'Email adress': 'Imel',
    'Confirm Password': 'Konfime modpas la',
    'The username field is required': 'Non itilizatè a obligatwa',
    'The password field is required': 'Modpas la obligatwa',
    'Configuration': 'Konfigirasyon',
    'User profile': 'Profil itilizatè a',
    'User status': 'Estati itilizatè a',
    'Manage Users': 'Jesyon itilizatè yo',
    'Users Management Message Header': 'La nou jere tout sak gen pou wè ak itilizatè yo sou sistèm nan.',
    'Create user': 'Kreye yon itilizatè',
    "Reset password": "Restore modpas",
    'User List': 'Lis itilizatè yo',
    'Administrator': 'Administratè',
    'Admin System': 'Administratè Sistèm',
    'Administration': 'Administrasyon',
    'Manage Counseling': 'Jesyon konsèy yo',
    'Counseling': 'Konsèy',
    'Counseling List': 'Lis konsèy yo',
    'Login': "Idantifye m",
    'Password': "Modpas",
    "Forgotten Password ?": "Ou bliye modpas la?",
    "Sign in": "Konekte",
    "Don’t have an account yet? ": "Ou poko gen yon kont? ",
    "Register here": "Anrejistre la",
    "Fill in the fields below to sign into your account.": "Ranpli espas anba yo pou ka konekte ak kont ou an",
    "Delete selected counseling": "Efase konsèy ki seleksyone yo",
    "This action is not reversible and will delete all selected counseling(s).": "Aksyon sa pa gen retounen epi lap efase tout konsèy ki seleksyone yo",
    "Are you sure to want to continue ?": "Eskew si ou vle kontinye ?",
    "Delete counseling": "Efase yon konsèy",
    "Cancel": "Anile",
    "Delete": "Efase",
    'Postpone publication': 'Pibliyel aprè',
    'Status': 'Estati',
    'Name': 'Non',
    'Publication date': 'Dat piblikasyon',
    'Preview': 'Apèsi',
    'Save draft': 'Bouyon',
    'Publish': 'Pibliye',
    'Add image': 'Mete yon foto',
    'Drop the files to start uploading': 'Mete dosye yo pou kòmanse telechaje yo',
    'Project title here...': 'Mete tit tèks lan la',
    'Your counseling text body here': 'Mete tit konsèy ou a la...',
    'Create new topic': 'Kreye yon nouvo sijè',
    'Fill in the fields below to create a new topic': 'Ranpli espas vid yo pou kreye yon nouvo sijè',
    'Counselings Management': 'Jere konsèy yo',
    'Here we manage all about counselings': 'Isit la nou jere tout sa ki gen pou wè ak konsèy',
    'Edit counseling': 'Modifye konsèy la',
    'We couldn\'t find any counseling matching your search criteria': 'Nou pa rive jwenn rezilta pou sa wap chèche a',
    'f': 'Fi',
    'm': 'Gason',
    'i': 'enkoni',
    'Provider': 'Prestatè',
    'Home': 'Akèy',
    'Agenda': 'Ajanda',
    'Messenger': 'Mesaj',
    'messenger': 'Mesaj',
    'Manage Officier': 'Ofisye done',
    'Form': 'Fòmilè',
    'List': 'Lis',
    'Meetings and Appointement': 'Rankont ak Randevou',
    'Check out the latest meetings and meetings in your calendar': 'Verifye denye rankont ak randevou ou yo la',
    'The event has been deleted': 'Randevou a anile',
    'The end date should be after start date': 'Dat li fini an dwe avan dat li komanse an',
    'The calendar has been successfully updated!': 'Kalandriye a modifye avèk siksè!',
    'Create new calendar event': 'kreye yon nouvo evènman',
    'Edit calendar event': 'Modify yon evènman nan kalandrye a',
    'Select your event type': 'Chwazi tip evènman',
    'Select prefered work day': 'Chwazi jou ou vle travay yo',
    'All days': 'Tout jou yo',
    'Event end date': 'Dat evènman ap fini an',
    'Appointments': 'Randevou',
    'appointment': 'Randevou',
    'Calendar': 'Kalandrye',
    'Welcome Dr': 'Byenvini Dòk',
    'Since your last login there were': 'Depi dènye koneksyonw lan gen',
    'and': 'epi',
    'new messages': 'nouvo mesaj',
    'new patients appointments': 'nouvo randevou',
    'Decline': 'Dekline',
    'Confirm': 'Konfime',
    'You confirmed this Appointment successfuly!': 'Ou konfime randevou sa avèk siksè.',
    'You declined this Appointment': 'Ou pa aksepte randevou sa.',
    'You canceled this appointment.': 'Ou anile randevou sa.',
    'Appointment Confirmation': 'Konfimasyon randevou',
    'Once you click on the YES button, this person will be added to your appointment list for consultation later. Do you really want to confirm ?': 'Depiw klike sou bouton WI a ,moun sa ap ajoute nan lis poul ka konsilte avèw apre. Eskew vle konfime sa vre?',
    'Appointment declination': 'Pa aksepte yon randevou',
    'Once click on the YES button, this person will be removed from your list of confirmation. This action is irreversible. Do you really want to decline ?': 'Depiw klike sou bouton WI a, Moun sa ap efase nan li konfimasyon w lan. Aksyon sa pap ka chanje ankò. Eskew pa vle aksepte vre?',
    'Declined': 'Dekline',
    'Pending': 'An atant',
    'Confirmed': 'Konfime',
    'Expired': 'Ekspire',
    'All': 'Tout',
    'Type': 'Tip',
    'Motivation': 'Motivasyon',
    'Request an appointment': 'Demand randevou yo',
    'Yes': 'Wi',
    'No': 'Non',
    'Filters': 'Filtre',
    'Search appointement...': 'Chèche yon randevou...',
    'Today work hour': 'Lè travay jodia',
    'You have no consultation(s) yet!': 'Ou poko gen konsiltasyon',
    'Your consulations': 'Konsiltasyon yo',
    'You are about to start a consultation for this patient.': 'Ou pral kòmanse konsiltasyon pou pasyan sa.',
    'When you click on the start button, you will be redirected to the consultation mode indicated.': 'Lew klike sou bouton kòmanse a, lap voyew sou mòd konsiltasyon an.',
    'Make sure you have isantePLUS open in your browser before starting your consultation.': 'Avan w kòmanse konsiltasyon an asirew ou louvri isantePLus sou yon paj wèb.',
    'Start of consultation': 'Kòmanse yon konsiltasyon',
    'Start': 'Kòmanse',
    'Consultation': 'Konsiltasyon',
    'Reason for consultation': 'Rezon konsiltasyon an',
    'Search online people...': 'Chèche yon moun an liy...',
    'View all': 'Wè tout',
    'Update Speciality': 'Modifye espesyalite',
    'New Speciality': 'Nouvo espesyalite',
    'Fill in the fields below to': 'Ranpli espas anba yo',
    'update': 'modifye',
    'create': 'kreye',
    'a speciality': 'yon espesyalite',
    'The speciality name is required': 'Non espesyalite a obligatwa',
    'The speciality description is required': 'Deskripsyon espesyalite a obligatwa',
    'Speciality': 'Espesyalite',
    'Description': 'Deskripsyon',
    'Save': 'Anrejistre',
    'The speciality(ies) has been deleted successfully': 'Espesyalite yo efase kòrèkteman!',
    'The speciality(ies) deletion failed': 'Espesyalite yo pa efase',
    'Delete speciality(ies)': 'Efase espesyalite a(yo)',
    'Manage Specialities': 'Jere espesyalite yo',
    'Speciality Management': 'Jesyon espesyalite yo',
    'Add speciality': 'Ajoute yon espesyalite',
    'Search by name or description...': 'Chèche avèk non oubyen deskripsyon',
    'We couldn\'t find any speciality matching your search criteria': 'Nou pa rive jwenn oken espesyalite pou sa ou ekri a',
    'Creator': 'Kreyatè',
    'Created date': 'Dat li fèt',
    'Editor': 'Editè',
    'Edited date': 'Dat li modifye',
    'Action': 'Aksyon',
    'View': 'Wè',
    'The speciality was added successfully': 'Espesyalite sa ajoute kòrèkteman!',
    'Speciality already exist': 'Espesyalite sa la deja',
    'conseil(s)': 'Konsèy',
    'All counseling': 'Tout konsèy yo',
    'Published counseling': 'Konsèy ki pibliye yo',
    'published': 'Pibliye',
    'draft': 'Bouyon',
    'Pending counseling': 'konsèy an atant',
    'In draft': 'Nan bouyon',
    'Counseling published for this month': 'Konsèy ki pibliye pou mwa sa',
    'Check how many counseling are publiseh per day for the current month': 'Gade konbyen konsèy ki pibliye pa jou pou mwa sa',
    'Create counseling': 'Nouvo konsèy',
    'delivered': 'livre',
    'viewed': 'ki wè',
    'Today targets reached': 'Objektif ki atenn jodia',
    'These are your performance indicators on delivered counseling view': 'Sa yo se endikatè sou konsèy ki delivre yo',
    'Delivered view': 'Wè sa ki livre yo',
    'Viewed view': 'Wè sa ki li yo',
    'Go to counselings home': 'Ale nan paj akèy konsèy lan',
    'Add new counseling': 'Ajoute nouvo konsèy',
    'New password': 'Nouvo modpas',
    'Enter the email used for registration to reset your password.': 'Mete imèl ou te mete pou enskri a pou w kapab chanje modpas ou.',
    'Want to try to sign in again?': 'Ou vle rekonekte ankò?',
    'The password reset instructions have been sent to your email': 'Nou voye sou imel ou enstriksyon pou chanje modpas ou',
    'Check your email for further instructions': 'Gade nan imel ou w ap jwenn rès enstriksyon yo',
    'Continue to login': 'Kontinye konekte',
    'Failed to recovery your account, email not found or time out': 'Malerezman nou pa rive jwenn kont ou, kapab se imel la ki pa bon oubyen pwoblèm entenèt',
    'Changed Password': 'Chanje modpas',
    'Fill the fields below to provide a new personal password.': 'Ranpli espas ki anba yo pou chanje modpas la',
    'Change password': 'Chanje modpas',
    'Password format not match': 'Fòma modpas la pa bon, li dwe genyen pou pi piti yon lèt majiskil oubyen yon chif',
    'Pasword is not identic': 'Modpas yo pa menm',
    'The password has been changed successful': 'Modpas la chanje avèk siksè',
    'You can sign in now': 'Ou ka konekte kounya',
    'Select speciality': 'Chwazi espesyalite ou',
    'Fill in the fields below to create and add a new user to the site': 'Ranpli fòm nan pou kreye yon nouvo itilizatè',
    'Select profile': 'Chwazi pwofil ou',
    'Search by name, email or username...': 'Chèche pa non, imel oswa non itilizatè',
    'We couldn\'t find any users matching your search criteria': 'Nou pa rive jwenn itilizatè pou kritè ou mete yo',
    'Fullname': 'Non konplè',
    'Language': 'Lang',
    'Phone': 'Telefòn',
    'Actions': 'Aksyon',
    'All users': 'Tout itilizatè yo',
    'Email': 'Imel',
    'pending': 'Atant',
    'active': 'Aktif',
    'inactive': 'Inaktif',
    'locked': 'Bloke',
    'System administrator': 'Administratè sistèm',
    'Care recipient': 'Benefisyè swen',
    'Care provider': 'Prestatè swen',
    'Rows per page': 'Liy pou chak paj',
    'Email address': 'Imel',
    'Select the locale for user': 'Seleksyone yon lang pou itilizatè a',
    'Select user profile': 'Seleksyone pwofil itilizatè a',
    'Select user locale': 'Seleksyone lang itilizatè an',
    'Update user': 'Modifye itilizatè a',
    'Add new user': 'Ajoute yon nouvo itilizatè ',
    'Counselings Dashboard': 'Bòd konsèy yo',
    'Profile settings': 'Paramèt pwofil',
    'Sign out': 'Dekonekte',
    'Manage Sites': 'Jesyon sit yo',
    'Address': 'Adrès',
    'Location': 'Rejyon',
    'Search by name, address or location...': 'Chèche pa non. adrès oubyen rejyon',
    'Manage Site': 'Jesyon Site',
    'Site Management Message Header': 'Isit lan nou jere tout sa ki gen pou wè ak jesyon sit yo',
    'Create site': 'Kreye on nouvo sit',
    'Close selected site': 'Femen sit seleksyone a',
    'We couldn\'t find any site matching your search criteria': 'Nou pa rive jwenn okenn sit ki koresponn ak kritè rechèch ou a',
    'Edit site': 'Modifye sit la',
    'Bulk actions': 'List aksyon yo',
    'Enable': 'Aktive',
    'Disable': 'Dezaktive',
    'Profile Update': 'Modifye pwofil la',
    'Status user': 'Modifye estati a',
    'Work time': 'Lè travay la',
    'The user account was updated successfully': 'Kont itilizatè a modifye avèk siksè',
    'The user account was created successfully': 'Kont itilizatè a kreye avèk siksè',
    'Start call': 'Kòmanse yon apèl',
    'Call type': 'Tip apèl la',
    'Video': 'Videyo',
    'Call Video': 'Apèl videyo',
    'Call Audio': 'Apèl vokal',
    'User profil information': 'Enfomasyon sou profil',
    'Personal Information': 'Detay sou kontak la',
    'Shared Files': 'Fichye ki pataje',
    'Chat': 'Tchat',
    'Meetings': 'Rankont',
    'You have no meetings!': 'Ou pa gen rankont',
    'Files': 'Fichye',
    'Online': 'An liy',
    'To day': 'Jodia',
    'Write your message here...': 'Tape mesaj ou an la...',
    'Choose an emoji': 'Chwazi on ikon reyaksyon',
    'Attach a file': 'Mete yon dosye',
    'Send voice': 'Voye nòt vokal',
    'Select site location': 'Seleksyone yon rejyon',
    'Search...': 'Rechèch...',
    'Switch language': 'Chanje lang',
    'Notifications': 'Notifikasyon',
    'Theme': 'Tèm',
    'Event start date': 'Dat evènman ap kòmanse',
    'Select frequency': 'Seleksyone frekans lan',
    'Add meeting': 'Mete disponiblite',
    'Save modifications': 'Konfime disponiblite',
    'Go home': 'Ale nan akèy la',
    'Another meeting is already defined at this time': 'Genyen yon lòt rankont gentan planifye pou menm lè sa',
    'Start date must be inferior to end date': 'Lè w ap kòmanse a dwe pi gran pase lè w ap fini an',
    'Start date and end date can\'t be same': 'Lè w ap kòmanse a pa dwe menm ak lè w ap fini an',
    'Mon': 'Len',
    'Tue': 'Mad',
    'Wed': 'Mèk',
    'Thu': 'Jed',
    'Fri': 'Ven',
    'Sat': 'Sam',
    'Sun': 'Dim',
    'Repeat one': 'Repete yon sèl fwa',
    'Only this event': 'Selman evènman sa',
    'All this month': 'Tout mwa sa',
    'All similar events': 'Tout evènman ki sanble yo',
    'Previous Day': 'Jou pase',
    'Today': 'Jodia',
    'Next Day': 'Demen',
    'Month': 'Mwa',
    'Week': 'Semèn',
    'Day': 'Jou',
    'Customize your consultation duration': 'Modifye tan konsiltasyon ou yo',
    'Select your site location': 'Chwazi rejyon lopital ou an',
    'Gender': 'Seks ou',
    'Update profile': 'Modifye pwofil',
    'Change profil info': 'Chanje enfòmasyon sou pwofil lan',
    'Fill in the fields below to change information about your profile': 'Ranpli espas anba yo pou chanje enfòmasyon sou pwofil ou',
    'Go back': 'Retounen nan Akey la',
    'Profile for': 'Pwofil ',
    'This is your profile page. Find all the informations about account and feel free to modify them': 'Sa se paj pwofil ou. Jwenn tout enfòmasyon sou kont ou ladan l epi modifye yo janw vle.',
    'Edit your profile': 'Modifye profil ou',
    'Information': 'Enfòmasyon',
    'Security': 'Sekirite',
    'Personal Details': 'Enfòmasyon pèsonel',
    'Manage informations related to your personal details': 'Jere enfòmasyon pèsonel ou yo',
    'Account Settings': 'Paramèt kont la',
    'Manage details related to your account': 'Jere enfòmasyon sou kont ou',
    'Account type': 'Tip kont la',
    'Account status': 'Estati kont lan',
    'Professional information': 'Enfòmasyon pwofesyonèl',
    'Manage details related to your associated email addresses': 'Jere enfòmasyon ki gen rapò ak imel ou',
    'Manage details related to your professional profile': 'Jere enfòmasyon ki gen rapò ak pwofesyon w',
    'Health center': 'Lopital',
    'Spécialisation': 'Espesyalizayon',
    'mns': 'mn',
    'Current Date': 'Dat chwazi a se: ',
    'all': 'Tout',
    'Stay connected': 'Ret konekte',
    'You have': 'Ou genyen',
    'Counseling reports dashboard': 'Bòd rapò konsey yo',
    'Meetings and Appointments': 'Reyinyon ak randevou',
    'cette demande de rendez-vous a été cancel': "Randevou sa anile",
    'Appointment is pending': 'Randevou an atant',
    'Appointment expired': 'Randevou ekspire',
    'End consultation': 'Fini konsiltasyon an',
    'Add patient to a category': 'Ajoute kategori pasyan an',
    'Please don\'t forget to add the patient category.': 'Sivouplè pa bliye met kategori pou pasyan sa.',
    'When you click on the end button, you will close the consultation.': 'Lew klike sou bouton fini an wap fini konsiltasyon an',
    'You are about to end the consultation with the patient.': 'Ou preske fini konsiltasyon an ak pasyan sa.',
    'End of consultation': 'Fen konsiltasyon an',
    'Stop consultation': 'Kanpe konsiltasyon an',
    'Stop Conversation': 'kanpe konsiltasyon an',
    'Reset Conversation': 'Rekomanse konsiltasyon an',
    'Start Conversation': 'komanse ak konsiltation an',
    'Please give the reason for your refusal': 'Bay rezon ki fe ou refizel lan',
    'The user profil was changed successfully': 'Pwofil itilizatè a chanje ak siksè',
    'reconnecting': 'Rekonekte...',
    'profile': 'Pwofil',
    'No publications today': 'Pas gen piblikasyon jodia',
    'Publish for a specific category': 'Pibliye pou on kategori mounn spesifik',
    'Postpone counseling publication': 'Voye piblikasyon an pou yon lot lè',
    'Go to counselings list': 'Ale nan lis konsey yo',
    'Refusal Reason': 'Rezon refi a',
    'Refusal Reason Error': 'Silvouplè bay ki rezon avan w anile randevou a.',
    'The account has been created successful':'Kont sa apèn kreye avèk siksè',
    'You will received an email when a administrator will validate your account': 'Ou genyen pou resevwa yon imel lè yon administratè valide kont ou an.',
    'You will received an email that confirm your account is active': 'Ou pra l resevwa yon imèl ki ap konfime ke kont ou an aktive',
    'profileUpdatedbeneficiaire': 'Chanjman pwofil an Benefisyè swen',
    'profileUpdatedadmin': 'Chanjman pwofil an Administratè sistèm',
    'profileUpdatedofficier':   'Chanjman pwofil an Ofisye done',
    'profileUpdatedprestataire': 'Chanjman pwofil an Prestatè swen',
    'of': 'pou',
    'users': 'itilizatè',
    'updatedinactive': 'Aktive kont',
    'updatedactive': 'Dezaktive kont',
    'cant call offline':'Ou paka pase apèl sa, ou pa konekte nan kounye a',
    'offline trying to reconnect':'Ou pa konekte kounye a, n ap eseye rekonekte w',
    'Messenger - Applications': 'Tchat apliksayon',
    'incommingCallVideo': 'Se pou yon randevou videyo',
    'incommingCallAudio': 'Se pou yon randevou odyo',
};

export default htJSON;