import {FC, ReactNode} from 'react';
import {Navigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import {Role, Status} from 'src/models/user';
import {useSelector} from "react-redux";
import {RootState} from "../../store";

interface GuestProps {
    children: ReactNode;
}

const Guest: FC<GuestProps> = ({children}) => {
    const {user, isLoading, isAuthenticated} = useSelector((state: RootState) => state.auth);
    if (isAuthenticated && user?.status == Status.PENDING) {
        return <Navigate to="/account/changed-password"/>;
    } else if (isAuthenticated) {
        return <Navigate to={user?.profile.page}/>;
    }
    return <>{isLoading === false && children}</>;
};

Guest.propTypes = {
    children: PropTypes.node
};

export default Guest;
