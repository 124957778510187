import { FC, ReactElement, Ref, forwardRef, useEffect, useState } from 'react';

import {
    alpha,
    Box,
    IconButton,
    Typography,
    styled,
    useTheme,
    Tooltip,
    Button,
    Dialog,
    Autocomplete,
    Avatar,
    Grid,
    TextField,
    Slide,
    CircularProgress,
    DialogActions,
    DialogContent,
    Zoom
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PlayCircleOutlineOutlined from '@mui/icons-material/PlayCircleOutlineOutlined';
import PauseCircleOutlineOutlined from '@mui/icons-material/PauseCircleOutlineOutlined';
import RestartAltRounded from '@mui/icons-material/RestartAltRounded';
import { useDispatch, useSelector } from 'src/store';
import { createConsultation } from 'src/slices/consulation';
import { startTimer } from 'src/slices/timer';
import { Consultation } from 'src/models/consultation';
import { setVisibility } from 'src/slices/timer';
import { Formik } from 'formik';
import { updateUserCategory } from 'src/service/account-service';
import * as Yup from 'yup';
import { downloadFile } from 'src/service/file-service';
import { TransitionProps } from '@mui/material/transitions';
import { useSnackbar } from 'notistack';
import { getValue } from 'src/utils/utils';


const TypographySucccess = styled(Typography)(
    ({ theme }) => `
        margin-top: ${theme.spacing(0.2)};
        margin-right: ${theme.spacing(1)};
        font-size: ${theme.typography.pxToRem(20)};
        color: ${theme.colors.secondary.main};
        font-weight: bold;

  `
);

const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(2)};
  height: ${theme.spacing(2)};
  border-radius: ${theme.general.borderRadiusLg};
  margin-right: ${theme.spacing(0.5)};
`
);


const DialogWrapper = styled(Dialog)(
    () => `
        .MuiDialog-paper {
          overflow: visible;
        }
  `
);

const Transition = forwardRef(function Transition(
    props: TransitionProps & { children: ReactElement<any, any> },
    ref: Ref<unknown>
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const Error = styled(Box)(
    ({ theme }) => `
    position: relative;
    color: #FF1943;
    font-size: 13px;
    text-transform: uppercase;
    font-family: "Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-weight: 400;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    text-transform: none;
    margin-left: 8px;
    margin-right: 8px;
    font-weight: bold;
`
);

const categories = [
    { label: 'HARSAH', value: 'HARSAH' },
    { label: 'Professionnels de Sexe', value: 'PS' },
    { label: 'Transgenre', value: 'TG' },
    { label: 'Utilisateur de drogues injectables', value: 'UDI' },
    { label: 'Clients des Professionnels(les) de Sexe', value: 'CPS' },
    { label: 'Inconu', value: 'INCONNU' }
];


const ConsultationBox: FC = () => {
    const theme = useTheme();
    const { t }: { t: any } = useTranslation();
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0);
    let timeout;
    const { consultation } = useSelector(state => state.consultations);
    const userCategory : string = consultation?.appointment?.categoryBeneficiaire;
    const { start } = useSelector(state => state.timer);
    const [running, setRunning] = useState(start);
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const [category, setCategory] = useState<{ label: string, value: string }>(getValue(categories, userCategory));
    const { enqueueSnackbar } = useSnackbar();


    const categoryProps = {
        options: categories,
        getOptionLabel: (option) => option.label,
    };

    const handleCreateUserSuccess = () => {
        enqueueSnackbar(t('The category was added successfully'), {
            variant: 'success',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right'
            },
            TransitionComponent: Zoom
        });
    };

    const handleEndConsultation = () => {
        const updatedConsultation: Consultation = {
            ...consultation,
            endDate: new Date(),
        };

        dispatch(startTimer(false));
        dispatch(setVisibility(false));
        setOpenDialog(false);
        dispatch(createConsultation(updatedConsultation));
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };



    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    useEffect(() => {
        timeout = setInterval(() => {
            if (start) {
                runTimes();
            }
        }, 1000);
        return () => clearTimeout(timeout);
    });

    const handleReset = () => {
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        dispatch(startTimer(false))
        clearTimeout(timeout);
    };

    const handleStop = () => {
        dispatch(startTimer(false))
        clearTimeout(timeout);
    };

    const runTimes = () => {
        setSeconds(seconds + 1);
        if (seconds == 59) {
            setMinutes(minutes + 1);
            setSeconds(0);
        }
        if (minutes == 59) {
            setHours(hours + 1);
            setMinutes(0);
        }
    }



    return (
        <>
            <Box
                display='flex'
                sx={{
                    pl: `${theme.spacing(1)}`,
                    pr: `${theme.spacing(1)}`,
                    m: `${theme.spacing(10)}`,
                }}
            >
                <Box>
                    <TypographySucccess >
                        {hours < 10 ? '0' + hours : hours}:
                        {minutes < 10 ? '0' + minutes : minutes}:
                        {seconds < 10 ? '0' + seconds : seconds}
                    </TypographySucccess>
                </Box>


                <Box mt={0.1}>
                    <Tooltip arrow title={t('Start Conversation')}>
                        <IconButtonWrapper
                            sx={{
                                transition: `${theme.transitions.create(['background'])}`,
                                color: theme.colors.success.main,

                                '&:hover': {
                                    background: alpha(theme.colors.secondary.main, 0.2),
                                    color: theme.colors.success.main,

                                }
                            }}
                            onClick={() => dispatch(startTimer(true))
                            }

                        >
                            <PlayCircleOutlineOutlined />
                        </IconButtonWrapper>
                    </Tooltip>

                    <Tooltip arrow title={t('Stop Conversation')}>
                        <IconButtonWrapper
                            sx={{
                                transition: `${theme.transitions.create(['background'])}`,
                                color: theme.colors.success.main,

                                '&:hover': {
                                    background: alpha(theme.colors.secondary.main, 0.2),
                                    color: theme.colors.secondary.main,
                                }
                            }}
                            color="success"
                            onClick={handleStop}

                        >
                            <PauseCircleOutlineOutlined />
                        </IconButtonWrapper>
                    </Tooltip>

                    <Tooltip arrow title={t('Reset Conversation')}>
                        <IconButtonWrapper
                            sx={{
                                transition: `${theme.transitions.create(['background'])}`,
                                color: theme.colors.success.main,

                                '&:hover': {
                                    background: alpha(theme.colors.secondary.main, 0.2),
                                    color: theme.colors.secondary.main,
                                }
                            }}
                            color="success"
                            onClick={handleReset}

                        >
                            <RestartAltRounded />
                        </IconButtonWrapper>
                    </Tooltip>

                    <Tooltip arrow title={t('Stop Conversation')}>
                        <Button
                            size="small"
                            color="success"
                            onClick={handleOpenDialog}
                            variant="contained"
                            sx={{
                                '&:hover': {
                                    background: alpha(theme.colors.error.main, 1),
                                }
                            }}
                        >
                            {t('Stop consultation')}
                        </Button>
                    </Tooltip>
                </Box>
            </Box>


            <DialogWrapper
                open={openDialog}
                maxWidth="md"
                fullWidth
                TransitionComponent={Transition}
                keepMounted
                onClose={handleCloseDialog}
            >
                <Formik
                    initialValues={{
                        category: '',
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        category: Yup.string(),
                    })}
                    onSubmit={async (
                        _values, { resetForm, setErrors, setStatus, setSubmitting }
                    ) => {
                        try {
                            setSubmitting(false);
                            await updateUserCategory(consultation.appointmentDTO.creator
                                , category?.value).then((res) => {
                                    if (res) {
                                        resetForm();
                                        setStatus({ success: true });
                                        handleCreateUserSuccess();
                                        handleEndConsultation();
                                    }
                                })

                        } catch (err) {
                            console.error(err);
                            setStatus({ success: false });
                            setErrors({ submit: err.message });
                            setSubmitting(false);
                        }
                    }
                    }
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        touched,
                        values,
                        getFieldMeta
                    }) => (

                        <form onSubmit={handleSubmit}>
                            <DialogContent
                                sx={{
                                    p: 3
                                }}
                            >
                                <Grid display='flex'>
                                    <Box
                                        sx={{
                                            paddingRight: 2,
                                            maxWidth: '70%',
                                            borderRight: `0.5px solid ${theme.colors.alpha.black[10]}`,
                                            marginRight: 2
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                pb: 1,
                                                fontSize: `${theme.typography.pxToRem(26)}`,
                                            }}
                                            variant="h3"
                                        >
                                            {t('End of consultation')}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {t('You are about to end the consultation with the patient.')}
                                        </Typography>
                                        <Typography variant="subtitle2">
                                            {t('When you click on the end button, you will close the consultation.')}
                                        </Typography>

                                        <Typography variant="h4" sx={{
                                            mt: 6,
                                        }}>
                                            {t('Please don\'t forget to add the patient category.')}
                                        </Typography>

                                        <Grid item xs={12} sx={{
                                            md: 6,
                                            pt: 2,
                                            pb: 2,
                                        }}>
                                            <Autocomplete
                                                disabled={(userCategory) ? true : false}
                                                sx={{ mr: 1 }}
                                                {...categoryProps}
                                                value={category}
                                                id='category'
                                                clearOnEscape
                                                onChange={(event, newValue) => {
                                                    setCategory(newValue)
                                                }}
                                                onBlur={handleBlur}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params} label={t("Add patient to a category")} />
                                                )}
                                                isOptionEqualToValue={(option, value) => option.code === value.code} />

                                            {errors.category && touched.category && <Error>{t(getFieldMeta("category").error)}</Error>}

                                        </Grid>
                                    </Box>
                                    <Box display='column'>
                                        <Box>
                                            <Avatar
                                                sx={{
                                                    mr: 1,
                                                    width: theme.spacing(14),
                                                    height: theme.spacing(14)
                                                }}
                                                variant="rounded"
                                                alt={consultation?.appointment?.personCreator?.fullname}
                                                src={downloadFile(consultation?.appointment?.personCreator?.picture)}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography variant="h3" component="h3" gutterBottom noWrap >
                                                {consultation.appointment?.personCreator?.fullname}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </DialogContent>
                            <DialogActions
                                sx={{
                                    p: 3
                                }}
                            >
                                <Button color="secondary" onClick={handleCloseDialog}>
                                    {t('Cancel')}
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={
                                        isSubmitting ? <CircularProgress size="1rem" /> : null
                                    }
                                    disabled={Boolean(errors.submit) || isSubmitting}
                                    variant="contained"
                                >
                                    {t('End consultation')}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </DialogWrapper>
        </>
    )
}

export default ConsultationBox;

