import {RouteObject} from 'react-router';
import ProviderUser from 'src/components/Authenticated';
import BaseLayout from 'src/layouts/ExtendedSidebarLayout/BaseLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import accountRoutes from './account';
import baseRoutes from './base';
import configurationRoutes, {Loader} from './configuration';
import officierRoutes from './officier';
import Configuration from "../components/Authenticated/configuration";
import Officier from "../components/Authenticated/officier";
import ProviderRoutes from "./provider";
import Provider from "../components/Authenticated/provider";
import {lazy} from "react";
import recipientRoutes from "./recipient";
import Authenticated from "../components/Authenticated/authenticated";

const UserProfile = Loader(
    lazy(() => import('src/content/configuration/Users/Single'))
);

const router: RouteObject[] = [
    {
        path: 'teleconsultation',
        children: [
            {
                path: 'configuration',
                element: (
                        <Configuration>
                            <ExtendedSidebarLayout/>
                        </Configuration>
                ),
                children: configurationRoutes
            },
            {
                path: 'officier',
                element: (
                        <Officier>
                            <ExtendedSidebarLayout/>
                        </Officier>
                ),
                children: officierRoutes
            },
            {
                path: 'provider',
                element: (
                        <Provider>
                            <ExtendedSidebarLayout/>
                        </Provider>
                ),
                children: ProviderRoutes
            },
            {
                path: 'recipient',
                children: recipientRoutes
            },
            {
                path: 'user',
                element: (
                    <Authenticated>
                        <ExtendedSidebarLayout/>
                    </Authenticated>
                ),
                children: [
                    {
                        path: 'profile',
                        element: <UserProfile/>
                    }
                ]

            },
        ]
    },

    {
        path: 'account',
        children: accountRoutes
    },
    {
        path: '',
        element: (<BaseLayout/>),
        children: baseRoutes
    }
];

export default router;
