import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as messengerReducer } from 'src/slices/messenger';
import { reducer as websocketReducer } from 'src/slices/websocket';
import { reducer as usersReducer } from 'src/slices/users';
import { reducer as notificationseducer } from 'src/slices/notifications';
import { reducer as consultationReducer } from 'src/slices/consulation';
import { reducer as timerReducer } from 'src/slices/timer';
import { reducer as authReducer } from 'src/slices/auth';

const rootReducer = combineReducers({
  auth: authReducer,
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  messenger: messengerReducer,
  websocket: websocketReducer,
  users: usersReducer,
  notifications: notificationseducer,
  consultations: consultationReducer,
  timer: timerReducer
});

export default rootReducer;
