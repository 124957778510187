import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from 'src/store';
import http from 'src/utils/http';


interface UsersState {

    page: any
    limit: any
    query: any
    users: any[]

}

const initialState: UsersState = {

    page: null,
    limit: 0,
    query: '',
    users: []
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        getUsers(state: UsersState, action: PayloadAction<any>) {
            const users = action.payload;
            if (users?.content?.length > 0) {
                state.users = users?.content
            } else {
                state.users = []
            }
        },
        setUser(state: UsersState, action: PayloadAction<any>) {
            const user = action.payload;
            state.users = state.users?.map(u => u.id === user.id ? user : u)
        },
        setPage(state: UsersState, action: PayloadAction<any>) {
            const page = action.payload;
            state.page = page
        },
        setQuery(state: UsersState, action: PayloadAction<any>) {
            const query = action.payload;
            state.query = query
        },
        setLimit(state: UsersState, action: PayloadAction<any>) {
            const limit = action.payload;
            state.limit = limit
        }
    }
});

export const reducer = slice.reducer;

export const setPage = (page): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setPage(page));
}
export const setQuery = (query): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setQuery(query));
}
export const setLimit = (limit): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setLimit(limit));
}

export const setUser = (user): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setUser(user));
}

export const getUsers = (data): AppThunk => async (dispatch) => {
    const users = await http.get("/message/last/discussion", {
        params: data,
    });
    dispatch(slice.actions.getUsers(users.data));
};

export const searchUser = (data, usersOnline): AppThunk => async (dispatch) => {
    const users = await http.get("/user/accounts", {
        params: data,
    });
    const values = users?.data;
    const content = values?.content?.map(user => {
        if (usersOnline?.length > 0 && usersOnline.includes(user.username)) {
            user.online = true;
        }
        return user;
    })
    if (content?.length > 0)
        values.content = content;
    if (values?.length > 0) {
        dispatch(slice.actions.getUsers(values));
    }
};


export default slice;
