import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppThunk } from 'src/store';


interface TimerState {
  start: boolean,
  isHidden: boolean,
}

const initialState: TimerState = {
  start: false,
  isHidden: false,
};

const slice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    setRunning(state: TimerState, action: PayloadAction<boolean>) {
        state.start = action.payload
      },
      setVisible(state: TimerState, action: PayloadAction<boolean>) {
        state.isHidden = action.payload
      },
  },
});

export const reducer = slice.reducer;


export const startTimer = (start: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setRunning(start));
};

export const setVisibility = (visibility: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setVisible(visibility));
};

export default slice;
