import {downloadFile} from 'src/service/file-service';
import {fr, ht, enUS} from 'date-fns/locale';
import {Role} from 'src/models/user';
import {Appointment} from 'src/models/event';
import {Consultation} from 'src/models/consultation';

export function isBodyIsEmpty(body) {
    return body == undefined || body == String('<p><br></p>') ? true : false;
}

export function isEmpty(string: String) {
    return string && string.trim()?.length == 0 ? true : false;
}

export const toDay = new Date().toISOString().split('T')[0];

export const getDateToString = (date: Date) => {
    try {
        return date.toISOString().split('T')[0];
    } catch (e) {
        return null;
    }
};

export const generateRoomId = () => Math.random().toString(36).substring(2, 10);

export function formatDateToString(d: Date) {
    var date = d
        .toLocaleString('en-US', {
            month: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            year: 'numeric',
            day: '2-digit',
            hour12: false
        })
        .split(' ');
    return new Date(date[0]).toISOString().split('T')[0] + 'T' + date[1];
}

export function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export async function downloadAnyFile(fileName, downUrl?) {
    const url = downUrl ? fileName : downloadFile(fileName);
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(link.href);
}

export function getCurrentTime() {
    var date = new Date();
    var hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    var minute =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    var second =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    return hour + ':' + minute + ':' + second;
}

export function groupArrayElementsByDate(elements, date) {
    const groupedObject = {};

    for (const element of elements) {
        const createdDate = new Date(element[date]).toDateString();
        if (!groupedObject[createdDate]) {
            groupedObject[createdDate] = [];
        }
        groupedObject[createdDate].push(element);
    }

    return groupedObject;
}

export function groupArrayElementsByElementName(
    elements: any,
    elementName: any
) {
    const groupedObject = {};

    for (const element of elements) {
        const createdElement = element[elementName];
        if (!groupedObject[createdElement]) {
            groupedObject[createdElement] = [];
        }
        groupedObject[createdElement].push(element);
    }
    return groupedObject;
}

export function getFileTypeFromURL(url: any) {
    if (url) {
        const link = url;
        const fileName = link.substring(link.lastIndexOf('/') + 1);

        const fileExtension = fileName.split('.').pop();

        const allowedExtensions = ['mp3', 'webm'];

        if (allowedExtensions.includes(fileExtension)) {
            return {
                extension: fileExtension,
                allowedExtension: allowedExtensions.includes(fileExtension)
            };
        } else {
            return {extension: null, allowedExtension: false};
        }
    }
}

export function getLastMessageByCreator(messages: any[]) {
    const creatorInfo = {};
    messages.forEach((message) => {
        const creatorId = message.personCreator.id;
        if (!creatorInfo[creatorId]) {
            creatorInfo[creatorId] = {
                creator: {
                    id: message.creator,
                    person: message.personCreator,
                    profile: message?.dest?.profile
                },
                lastMessage: message,
                toUser: message.toUser,
                messageCount: 1
            };
        } else {
            creatorInfo[creatorId].messageCount += 1;
            if (message.created > creatorInfo[creatorId].lastMessage.created) {
                creatorInfo[creatorId].lastMessage = message;
            }
        }
    });
    return creatorInfo;
}

export function isAudioFile(file) {
    if (file) {
        const fileNameParts = file.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
        const audioExtensions = ['mp3', 'wav', 'ogg', 'flac', 'aac', 'm4a', 'webm'];
        return audioExtensions.includes(fileExtension);
    }
}

export function isPictureFile(file) {
    if (file) {
        const fileNameParts = file.split('.');
        const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase();
        const audioExtensions = ['jpg', 'png', 'jpeg', 'webp', 'gif', 'bmp'];
        return audioExtensions.includes(fileExtension);
    }
}

export const toTitleCase = (chaine: string): string => {
    return chaine.charAt(0).toUpperCase() + chaine.slice(1);
};

export function textToSpeech(text: string) {
    const synth = window.speechSynthesis;
    const u = new SpeechSynthesisUtterance(text);
    const voices = synth.getVoices();
    u.voice = voices[6];
    u.pitch = 1;
    u.rate = 1;
    u.volume = 1;
    synth.speak(u);
    return () => {
        synth.cancel();
    };
}

export const concatenatedList = (list1: any[], list2: any[]) => {
    const list = [...list1, ...list2].reduce((accumulator, currentItem) => {
        const existingItem = accumulator.find((item) => item.id === currentItem.id);

        if (!existingItem) {
            accumulator.push(currentItem);
        }
        return accumulator;
    }, []);

    return list;
};

export const getLocal = (language) => {
    if (language === 'fr') return fr;
    if (language === 'en') return enUS;
    if (language === 'ht') return ht;
};
export const getUniqueUsersWithLatestMessages: any = (messages: any[]) => {
    const messageDict = {};

    messages.forEach((item) => {
        const personId = item.id;
        const messageCreated = item.lastMessage.id;

        if (
            !messageDict[personId] ||
            messageCreated > messageDict[personId].lastMessage.id
        ) {
            messageDict[personId] = item;
        }
    });

    return Object.values(messageDict);
};

export const isPrestataire = (profile) =>
    profile && profile.role == Role.PRESTATAIRE;

export function formatConsultation(
    startDate: Date,
    endDate: Date,
    appointement: Appointment
): Consultation {
    return {
        startDate: startDate,
        endDate: endDate,
        appointmentDTO: appointement
    };
}

export const getValue = (options, value) => {
    return options.find((l) => l.value === value) || null;
};

export function getUsersOnline(usersOnline, users: any[]): any[] {
    return users?.map((user) => {
        if (usersOnline?.length > 0 && usersOnline.includes(user.username)) {
            return {...user, online: true};
        }
        return user;
    });
}

export function getUserFromDiscussionByCreator(discussion, currentUserId) {
    return discussion?.creator?.id == currentUserId
        ? discussion?.toUser
        : discussion?.creator;
}

export const topic = (name: string) => `/topic/v2/${name}`;

export  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return "mobile";
    }
    return "desktop";
};