import {Suspense, lazy} from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import LoginBasic from 'src/content/pages/Auth/Login/Basic';
import ProviderUser from "../components/Authenticated";
import Authenticated from "../components/Authenticated/authenticated";

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

// Account
const ChangedPassword = Loader(
    lazy(() => import('src/content/pages/Auth/ChangedPassword'))
);

const RegisterBasic = Loader(
    lazy(() => import('src/content/pages/Auth/Register/Basic'))
);

const RecoverPassword = Loader(
    lazy(() => import('src/content/pages/Auth/RecoverPassword'))
);

const accountRoutes = [
    {
        path: 'login',
        element: (
            <Guest>
                <LoginBasic/>
            </Guest>
        )
    },
    {
        path: 'register',
        element: (
            <Guest>
                <RegisterBasic/>
            </Guest>
        )
    },
    {
        path: 'recover-password',
        element: (<Guest>
                <RecoverPassword/>
            </Guest>
        )
    },

    {
        path: 'changed-password',
        element: (<Authenticated>
                <ChangedPassword/>
            </Authenticated>
        )
    }
];

export default accountRoutes;
