import http from "../utils/http";

export async function uploadFile(photo: File) {
    const formData = new FormData();
    formData.append("file", photo)
    return http.post('/file/uploadFile', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export async function uploadAudioFile(audio) {
    const formData = new FormData();
    formData.append("file", audio)
    return http.post('/file/uploadFile', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

export async function uploadMultipleFile(files) {
    const formData = new FormData();
    files.forEach((file) => {
        formData.append('files', file);
    });
    return http.post('/file/uploadMultipleFiles', formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}


export async function downloadFileByName(fileName) {

    return http.get(`/file/downloadFile/${fileName}`);
}



export async function deletePicture(filename: string) {
    return http.delete(`/user/deleteFile/${filename}`)
}

export function downloadFile(path: string | undefined) {
    if (path) {
        return `${location.origin}/api/file/downloadFile/${path}`
    }
    return "";
}