import {Suspense, lazy} from 'react';
import {Navigate} from 'react-router-dom';
import ProviderUser from 'src/components/Authenticated';
import Configuration from 'src/components/Authenticated/configuration';
import Officier from 'src/components/Authenticated/officier';
import SuspenseLoader from 'src/components/SuspenseLoader';
import {RouteObject} from "react-router";

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

const Counseling = Loader(lazy(() => import('src/content/officier/counseling/create')));
const ListCounseling = Loader(lazy(() => import('src/content/officier/counseling/list')));
const DashboardCounseling = Loader(lazy(() => import('src/content/officier/counseling/dashboard/index')));



const officierRoutes: RouteObject [] = [
        {
            path: '',
            element: <ListCounseling/>,
        },
        {
            path: 'counseling',
            children: [
                {
                    path: '',
                    element: <Navigate to="dashboard" replace/>
                },
                {
                    path: 'form',
                    element: <Counseling/>,
                },
                {
                    path: 'list',
                    element: <ListCounseling/>
                },
                {
                    path: 'dashboard',
                    element: <DashboardCounseling/>
                },
            ]
        }
    ]
;

export default officierRoutes;
