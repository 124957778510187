import { FC, ReactNode, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { Role } from "../../models/user";
import Status404 from "../../content/pages/Status/Status404";
import ProviderUser, { AuthenticatedProps } from "./index";


const Configuration: FC<AuthenticatedProps> = (props: { children: any }) => {
    const { children } = props;
    const { user, isAuthenticated, isLoading } = useAuth();
    if(!isAuthenticated){
        return <Navigate to="/account/login"/>;
    } else if (!(Role.ADMIN == user?.profile?.role || Role.SUPER == user?.profile?.role)) {
        return <Navigate to="/status/unauthorized" />;
    }
    return (<>{isLoading===false && children}</>);
};

Configuration.propTypes = {
    children: PropTypes.node
};

export default Configuration;
