import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {AppThunk} from 'src/store';

interface WebSocketState {
    message: any,

}

const initialState: WebSocketState = {
    message: ''
};

const slice = createSlice({
    name: 'websocket',
    initialState,
    reducers: {
        sendMessage(state: WebSocketState, action: PayloadAction<any>) {
            state.message = action?.payload
        },
    },
});

export const reducer = slice.reducer;

// Asynchronous action to send message
export const sendMessage = (message: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.sendMessage(message));
};

export default slice;
