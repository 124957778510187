import {FC} from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import {Role} from "../../models/user";
import {AuthenticatedProps} from "./index";
import {Navigate} from 'react-router-dom';
import {RootState, useSelector} from "../../store";


const Officier: FC<AuthenticatedProps> = (props: { children: any }) => {
    const {children} = props;
    const {user, isLoading, isAuthenticated} = useSelector((state: RootState) => state.auth);

    if (!isAuthenticated) {
        return <Navigate to="/account/login"/>;
    } else if (!(Role.ADMIN == user?.profile?.role || Role.SUPER == user?.profile?.role || Role.OFFICIER == user?.profile?.role || Role.PRESTATAIRE == user?.profile?.role)) {
        return <Navigate to="/status/unauthorized"/>;
    }
    return (<>{isLoading === false && children}</>);
};

Officier.propTypes = {
    children: PropTypes.node
};

export default Officier;
