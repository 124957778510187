import {useNavigate, useRoutes} from 'react-router-dom';
import router from 'src/router';
import {SnackbarProvider} from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useAuth from 'src/hooks/useAuth';
import {CssBaseline} from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {RootState, useDispatch, useSelector} from './store';
import {deliveredNotifications, unreadNotifications} from './slices/notifications';
import slice, {
    resetStateMessenger,
} from './slices/messenger';
import {Navigate, useLocation} from 'react-router';
import ProviderUser from "./components/Authenticated";
import {loggedOut} from "./slices/auth";
import {useStompClient, useSubscription} from "react-stomp-hooks";
import {topic} from "./utils/utils";

const InactivityTimeout = 7 * 60 * 1000; // 7 minutes in milliseconds


function App() {
    const content = useRoutes(router);
    const auth = useAuth();
    const audioRef = useRef(null);
    const audioRefNotice = useRef(null);
    const dispatch = useDispatch()
    const {remoteStream, callState, callVideoAccepted} = useSelector(state => state.messenger)
    const {isAuthenticated, token} = useSelector(state => state.auth)
    const location = useLocation()
    const messengerPath = '/teleconsultation/provider/messenger'
    const [lastTransactionTime, setLastTransactionTime] = useState<number | null>(null);
    const stompClient = useStompClient();
    // Function to reset the inactivity timer
    const resetInactivityTimer = () => {
        setLastTransactionTime(Date.now());
    };

    // Function to handle mouse click event
    const handleMouseClick = (e) => {
        handleTransaction()
    };

    // Function to handle key press event
    const handleKeyDown = (e) => {
        handleTransaction()
    };

    // Function to handle mouse move event
    const handleMouseMove = (e) => {
        handleTransaction()
    };

    // Effect to monitor the inactivity timer
    useEffect(() => {
        const checkInactivity = async () => {
            if (lastTransactionTime !== null) {
                const currentTime = Date.now();
                if (currentTime - lastTransactionTime > InactivityTimeout) {
                    // Automatically log out the user here due to inactivity
                    if (isAuthenticated) {
                        if (callState == "answered") return
                        else {
                            dispatch(slice.actions.setRemoteDevice(null))
                            dispatch(loggedOut(stompClient))
                            auth.logout(stompClient);
                        }
                    }
                }
            }
        };

        const interval = setInterval(checkInactivity, 1000); // Check inactivity every second
        return () => clearInterval(interval); // Clean up the interval on component unmount

    }, [lastTransactionTime, isAuthenticated, remoteStream, callState, callVideoAccepted]); // Re-run the effect whenever the lastTransactionTime changes

    // Function to handle a transaction and reset the inactivity timer
    const handleTransaction = () => {
        resetInactivityTimer(); // Reset the inactivity timer by updating the last transaction time
    };


    useLayoutEffect(() => {
        try {
            if (audioRefNotice?.current) {
                audioRefNotice.current = new Audio('/audio.mp3');
                dispatch(slice.actions.setAudioRef({audioRef: audioRefNotice, type: "notification"}));
            }
        } catch (e) {
        }
    }, []);

    useLayoutEffect(() => {
        if (audioRef?.current) {
            try {
                audioRef.current = new Audio('/ringtone.mp3');
                dispatch(slice.actions.setAudioRef({audioRef: audioRef, type: "call"}));
            } catch (e) { }
        }
    }, []);


    useEffect(() => {
        if (isAuthenticated)
            dispatch(unreadNotifications())
    }, [dispatch, isAuthenticated])


    useEffect(() => {
        if (location.pathname !== messengerPath) {
            dispatch(resetStateMessenger())
        }
    }, [location])

    useSubscription(topic(`get-session-id/${token}`), (message) => {
        dispatch(slice.actions.setSessionId(message.body));
    });

    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                    }}
                >
                    <CssBaseline/>
                    <div
                        onClick={handleMouseClick}
                        onKeyDown={handleKeyDown}
                        onMouseMove={handleMouseMove}
                        style={{width: '100%', height: '100vh'}}
                        tabIndex={0}>
                        <ProviderUser>
                            {content}
                        </ProviderUser>
                        <audio ref={audioRef} hidden={true}/>
                        <audio ref={audioRefNotice} hidden={true}/>
                    </div>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
}
export default App;