import http from "../utils/http";
import { User} from "../models/user";
import {Profile} from "../models/profile";

export async function getCurrentUser() {
    const response = await http.get('/user/current/');
    return response.data;
}

export async function login(credentials) {
    const response = await http.post("/authenticate", credentials);
    return response.data;
}

export async function getAccounts(data) {
    const users = await http.get("/user/accounts", {
        params: data,
    });
    return users.data;
}

export async function getDashboard(data) {
    const dashboard = await http.get("/user/dashboard", {
        params: data,
    });
    return dashboard.data;
}

export async function getAccountsByStatus(data) {
    const users = await http.get("/user/accountsByStatus", {
        params: data,
    });
    return users.data;
}

export async function getProfiles() {
    const profiles = await http.get("/user/profiles");
    return profiles.data;
}

export async function getProfileByName(name: string) {
    const profile = await http.get<Profile>(`/user/profile/${name}`);
    return profile.data;
}


export async function saveAccount(account: User) {
    return http.post<User>("/user/register", account);
}


export async function changePassword(account: User) {
    return http.post<User>("/user/change-password", account);
}


export async function recoverPassword(email) {
    return http.post("/user/auto-reset-password", email);
}

export async function updateAccount(accounts: User[]) {
    return http.post<User>("/user/accounts", accounts);
}

export async function updateProfile(account: User) {
    return http.post<User>("/user/profile", account);
}

export async function getUserByEmail(email: string) {
    try {
        const user = await http.get(`/user/email/${email}`);
        return user.data;
    } catch (err) {
        console.error(err);
    }
}

export async function getUserByUsername(username: string) {
    try {
        const user = await http.get(`/user/username/${username}`);
        return user.data;
    } catch (err) {
        console.error(err);
    }
}

export async function validatePassword(password: string) {
    try {
        const usr = await http.get(`/user/verify-password/${password}`);
        return usr.data;
    } catch (err) {
        console.error(err);
    }
}

export async function updateUserCategory(userId: number, category: string) {
    try {
        const res = await http.post(`/user/update/category/${userId}/${category}`);
        return res.data;
    } catch (err) {
        console.error(err);
    }
}
