import axios from "axios";

const http =axios.create({
    baseURL: "/api",
    timeout: 40000,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": '*',
        Authorization: getAuthorizationHeader()
    }
});

export function getAuthorizationHeader() : string{
    const token = getToken(); // Replace with your logic to get the token
    if (token) {
        return `Bearer ${token}`;
    }
    return null;
}

export function getToken() {
    return localStorage.getItem('accessToken');
}


http.interceptors.request.use((config) => {
    config.headers.Authorization = getAuthorizationHeader();
    return config;
}, (error) => {
    return Promise.reject(error);
});

export default http;
