import {Suspense, lazy} from 'react';
import {Navigate} from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

const Calendar = Loader(lazy(() => import('src/content/provider/Calendar')));
const Provider = Loader(lazy(() => import('src/content/provider/Healthcare')));
const Messenger = Loader(lazy(() => import('src/content/provider/Messenger')));
const Notifications = Loader(lazy(() => import('src/content/provider/Notifications')));
const Counseling = Loader(lazy(() => import('src/content/officier/counseling/dashboard')));

const ProviderRoutes = [
    {
        path: '',
        element: <Navigate to="dashboard" replace/>
    },
    {
        path: 'dashboard',
        element: <Provider />,

        state: {},
    },
    {
        path: 'agenda',
        element: <Calendar />,
        state: {}
    },
    {
        path: 'messenger',
        element: <Messenger />,
        state: {}
    },
    {
        path: 'notifications',
        element: <Notifications />,
        state: {}
    },
    {
        path: 'counselings-dashboard',
        element: <Counseling />,
        state: {}
    }

];

export default ProviderRoutes;
