import {useState} from 'react';
import useAuth from 'src/hooks/useAuth';
import {useNavigate} from 'react-router-dom';

import {
    Avatar,
    Box,
    Typography,
    useTheme
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {downloadFile} from "../../../../service/file-service";
import {useSelector} from "react-redux";
import {RootState} from "../../../../store";


function SidebarTopSection() {
    const {t}: { t: any } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const {user} = useSelector((state: RootState) => state.auth);

    const [isOpen, setOpen] = useState<boolean>(false);


    const handleClose = (): void => {
        setOpen(false);
    };


    return (
        <Box
            sx={{
                textAlign: 'center',
                mx: 2,
                pt: 1,
                position: 'relative',
            }}
        >
            <Avatar
                sx={{
                    width: 68,
                    height: 68,
                    mb: 2,
                    mx: 'auto'
                }}
                alt={user.person.fullname}
                src={downloadFile(user.person.picture)}
            />
            <Typography
                variant="h4"
                sx={{
                    color: `${theme.colors.alpha.trueWhite[100]}`
                }}
            >
                {user.person.fullname}
            </Typography>
            <Typography
                variant="subtitle1"
                sx={{
                    color: `${theme.colors.alpha.trueWhite[70]}`
                }}
            >
                {t(user?.profile?.name)}
            </Typography>
        </Box>
    );
}

export default SidebarTopSection;
