import {useContext} from 'react';

import {
    Box,
    alpha,
    Stack,
    lighten,
    Divider,
    IconButton,
    Tooltip,
    styled,
    useTheme
} from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {SidebarContext} from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderTheme from './Buttons/Theme';
import {isPrestataire} from 'src/utils/utils';
import useAuth from 'src/hooks/useAuth';
import {RootState, useDispatch, useSelector} from 'src/store';
import ConsultationBox from './Buttons/Chronometer';
import {useTranslation} from "react-i18next";
import CheckBoxOutlineBlank from "@mui/icons-material/ReplayTwoTone";
import {IconButtonWrapper} from "../../../content/provider/Messenger";
// import {SocketContext} from "../../../contexts/UserContext";
import {useStompClient} from "react-stomp-hooks";

const HeaderWrapper = styled(Box)(
    ({theme}) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
    const {sidebarToggle, toggleSidebar} = useContext(SidebarContext);
    const theme = useTheme();
    const {user} = useAuth();
    const {t}: { t: any } = useTranslation();
    const {isHidden} = useSelector(state => state.timer);
    const dispatch = useDispatch()
    const {isAuthenticated, token} = useSelector(state => state.auth)
    const stompClient = useStompClient();

    return (
        <HeaderWrapper
            display="flex"
            alignItems="center"
            sx={{
                boxShadow:
                    theme.palette.mode === 'dark'
                        ? `0 1px 0 ${alpha(
                            lighten(theme.colors.primary.main, 0.7),
                            0.15
                        )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
                        : `0px 2px 8px -3px ${alpha(
                            theme.colors.alpha.black[100],
                            0.2
                        )}, 0px 5px 22px -4px ${alpha(
                            theme.colors.alpha.black[100],
                            0.1
                        )}`,
                backgroundColor: `${!(stompClient && stompClient.connected) ? 'orange' : ''}`
            }}
        >
            <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem/>}
                alignItems="center"
                spacing={2}
            >
                <HeaderTheme/>
                {(isPrestataire(user?.profile) && isHidden) && (<ConsultationBox/>)}
            </Stack>
            <Box display="flex" alignItems="center">
                <HeaderButtons/>
                <HeaderUserbox/>
                <Box
                    component="span"
                    sx={{
                        ml: 2,
                        display: {lg: 'none', xs: 'inline-block'}
                    }}
                >
                    <Tooltip arrow title="Toggle Menu">
                        <IconButton color="primary" onClick={toggleSidebar}>
                            {!sidebarToggle ? (
                                <MenuTwoToneIcon fontSize="small"/>
                            ) : (
                                <CloseTwoToneIcon fontSize="small"/>
                            )}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </HeaderWrapper>
    );
}

export default Header;
