import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import {AppThunk} from 'src/store';
import http from 'src/utils/http';
import {concatenatedList, groupArrayElementsByDate} from 'src/utils/utils';
import {Appointment} from '../models/event';
import useAuth from '../hooks/useAuth';
import {User} from 'src/models/user';

type CallState = 'new' | 'outgoing' | 'incoming' | 'answered' | 'rejected';
type CallType = 'audio' | 'video' | null;

interface MessengerState {
    messengers: any[];
    messages: any[];
    messagesNotification: any[];
    lastActiveDate: Date;
    messageGroupByDate: any;
    remoteDevice: "desktop" | "mobile" | "tablet" | null;

    callState: CallState;
    callType: CallType;
    room: string | null;
    currentAppointment: Appointment | null;
    userSelected: any;
    showDialogCall: boolean
    //End

    callAccepted: boolean;
    callVideoAccepted: boolean;
    callEnded: boolean;
    callVideoEnded: boolean;
    remoteStream: any;
    videoCall: any;
    myCurrentVideo: any;
    isIncomingCall: boolean;
    page: number;
    latDiscussion: any[];
    audioRef: any;
    audioRefNotice: any;
    localStream: any;
    isCaller: boolean;
    usersOnline: Array<String>;
    sessionId: string;
}

const initialState: MessengerState = {
    messengers: [],
    messages: [],
    messagesNotification: [],
    lastActiveDate: null,
    messageGroupByDate: {},
    userSelected: null,
    callVideoAccepted: false,
    callAccepted: false,
    callVideoEnded: false,
    callEnded: false,
    remoteStream: null,
    localStream: null,
    isCaller: false,
    room: null,
    videoCall: {},
    myCurrentVideo: null,
    isIncomingCall: false,
    page: 0,
    callType: null,
    latDiscussion: [],
    audioRef: null,
    audioRefNotice: null,
    currentAppointment: null,
    usersOnline: [],
    callState: 'new',
    showDialogCall: false,
    sessionId: null,
    remoteDevice: null
};

const slice = createSlice({
    name: 'messenger',
    initialState,
    reducers: {
        getMessengers(state: MessengerState, action: PayloadAction<any>) {
            const messengers = action.payload;
            let ms = [];

            if (messengers) {
                ms = concatenatedList(state.messengers, [messengers]);
                if (ms.length > 0) {
                    state.messageGroupByDate = groupArrayElementsByDate(
                        ms.sort((a, b) => a.id - b.id),
                        'created'
                    );
                    state.messengers = ms.sort((a, b) => a.id - b.id);
                    state.lastActiveDate = new Date(ms[ms.length - 1]?.created);
                    state.messages = [...state.messages, messengers];
                }
            }
        },
        getMessageByUserId(state: MessengerState, action: PayloadAction<any>) {
            const messages = action.payload;
            if (messages.length > 0) {
                state.messengers = messages;
                state.messageGroupByDate = groupArrayElementsByDate(
                    messages,
                    'created'
                );
                state.lastActiveDate = new Date(messages[messages.length - 1]?.created);
                state.page = 0;
            }
        },
        getLatDiscussion(state: MessengerState, action: PayloadAction<any>) {
            const latDiscussion = action.payload;
            if (latDiscussion.length > 0) {
                state.latDiscussion = latDiscussion;
            }
        },
        setUserSelected(state: MessengerState, action: PayloadAction<any>) {
            state.userSelected = action.payload;
        },
        setCallState(state: MessengerState, action: PayloadAction<CallState>) {
            state.callState = action.payload;
        },

        resetStateMessenger(state: MessengerState, action: PayloadAction) {
            state.messageGroupByDate = null;
            state.userSelected = null;
            state.messengers = [];
        },

        setCallAccepted(state: MessengerState, action: PayloadAction<boolean>) {
            state.callAccepted = action.payload;
        },

        setCallVideoAccepted(
            state: MessengerState,
            action: PayloadAction<boolean>
        ) {
            state.callVideoAccepted = action.payload;
        },

        setCallEnded(state: MessengerState, action: PayloadAction<boolean>) {
            state.callEnded = action.payload;
        },
        setCallVideoEnded(state: MessengerState, action: PayloadAction<boolean>) {
            state.callVideoEnded = action.payload;
        },
        setRemoteStream(state: MessengerState, action: PayloadAction<any>) {
            state.remoteStream = action.payload;
        },
        setDialogCall(state: MessengerState, action: PayloadAction<boolean>) {
            state.showDialogCall = action.payload;
        },
        setRemoteDevice(state: MessengerState, action: PayloadAction<"mobile" | "tablet" | "desktop">) {
            state.remoteDevice = action.payload;
        },
        setVideoCall(state: MessengerState, action: PayloadAction<any>) {
            state.videoCall = action.payload;
        },
        setMyVideo(state: MessengerState, action: PayloadAction<any>) {
            state.myCurrentVideo = action.payload;
        },
        setIsIncomingCall(state: MessengerState, action: PayloadAction<boolean>) {
            state.isIncomingCall = action.payload;
        },
        setPage(state: MessengerState, action: PayloadAction<any>) {
            const {page, messages} = action.payload;
            if (messages?.length > 0) {
                const combinedList = concatenatedList(state.messengers, messages);
                state.messageGroupByDate = groupArrayElementsByDate(
                    combinedList.sort((a, b) => a.id - b.id),
                    'created'
                );
                state.messengers = combinedList.sort((a, b) => a.id - b.id);
                state.page = page;
            }
        },
        setCallType(state: MessengerState, action: PayloadAction<CallType>) {
            state.callType = action.payload;
        },
        setLocalStream(state: MessengerState, action: PayloadAction<any>) {
            state.localStream = action.payload;
        },
        setCaller(state: MessengerState, action: PayloadAction<boolean>) {
            state.isCaller = action.payload;
        },
        setRoom(state: MessengerState, action: PayloadAction<string>) {
            state.room = action.payload;
        },
        setSessionId(state: MessengerState, action: PayloadAction<string>) {
            state.sessionId = action.payload;
        },
        setAudioRef(
            state: MessengerState,
            action: PayloadAction<{ type: string; audioRef: any }>
        ) {
            const {audioRef, type} = action.payload;
            if (type == 'call') state.audioRef = audioRef;
            else {
                state.audioRefNotice = audioRef;
            }
        },
        audioRefAction(
            state: MessengerState,
            action: PayloadAction<{ event: string; type: string }>
        ) {
            try {
                const {event, type} = action.payload;
                if (type == 'call' && state.audioRef) {
                    switch (event) {
                        case 'play':
                            state.audioRef.current.play();
                            break;
                        case 'stop':
                            state.audioRef.current.pause();
                            break;
                    }
                }
                if (type == 'notification' && state.audioRefNotice) {
                    switch (event) {
                        case 'play':
                            state.audioRefNotice.current.play();
                            break;
                        case 'stop':
                            state.audioRefNotice.current.pause();
                            break;
                    }
                }
            } catch (e) {
                console.error("can't play or pause sound notification");
            }
        },
        setCurrentAppointment(
            state: MessengerState,
            action: PayloadAction<Appointment>
        ) {
            state.currentAppointment = action.payload;
        },
        setUsersOnline(
            state: MessengerState,
            action: PayloadAction<{ users?: Array<string>; user?: string }>
        ) {
            const {users, user} = action.payload;
            if (users) state.usersOnline = users;
            else if (user) state.usersOnline = [...state.usersOnline, user];
        },
        removeUsersOnline(
            state: MessengerState,
            action: PayloadAction<{ user: string }>
        ) {
            const {user} = action.payload;
            if (state.usersOnline?.length > 0) {
                if (state.usersOnline.includes(user))
                    state.usersOnline = state.usersOnline.filter((e) => e != user);
            }
        }
    }
});

export const reducer = slice.reducer;

export const setUserSelected =
    (user): AppThunk =>
        async (dispatch, getState) => {
            dispatch(getMessageByUserId(user?.id));
            const response = await http.get(`/user/find/${user?.id}`);
            const data = response?.data;
            dispatch(slice.actions.setUserSelected(data));
        };

export const getMessengers =
    (messengers): AppThunk =>
        async (dispatch) => {
            dispatch(
                slice.actions.getMessengers({
                    creator: messengers?.creator,
                    toUser: messengers?.toUser,
                    ...messengers?.message
                })
            );
        };

export const setMessenger =
    (messengers): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.getMessengers(messengers));
        };

export const resetStateMessenger = (): AppThunk => async (dispatch) => {
    dispatch(slice.actions.resetStateMessenger());
};

export const getMessageByUserId =
    (toUser: string): AppThunk =>
        async (dispatch) => {
            const response = await http.get(
                `/message/toUser?page=${0}&size=${20}&idUser=${toUser}`
            );
            const data = response?.data?.content;
            if (data?.length > 0) {
                dispatch(
                    slice.actions.getMessageByUserId(data.sort((a, b) => a?.id - b?.id))
                );
            } else {
                dispatch(slice.actions.getMessageByUserId([]));
            }
        };

export const setPage =
    (toUser, page): AppThunk =>
        async (dispatch) => {
            const response = await http.get(
                `/message/toUser?page=${page}&size=${100}&idUser=${toUser}`
            );
            const data = response?.data?.content;
            if (data?.length > 0) {
                dispatch(slice.actions.setPage({page: page, messages: data}));
            } else {
                dispatch(slice.actions.setPage({page: page, messages: []}));
            }
        };

export const getLatDiscussion =
    (usersOnline, currentUserId): AppThunk =>
        async (dispatch) => {
            const response = await http.get(`/message/last/discussion?page=0&size=20`);
            const value = response.data?.content?.map((discussion) => {
                if (usersOnline?.length > 0) {
                    if (discussion.toUser.id == currentUserId) {
                        if (usersOnline.includes(discussion.creator.username)) {
                            discussion.creator.online = true;
                        }
                    } else {
                        if (usersOnline.includes(discussion.toUser.username)) {
                            discussion.toUser.online = true;
                        }
                    }
                    return discussion;
                } else return discussion;
            });
            dispatch(slice.actions.getLatDiscussion(value));
        };

export const setCallAccepted =
    (value): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setCallAccepted(value));
        };

export const setCallVideoAccepted =
    (value): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setCallVideoAccepted(value));
        };

export const setCallEnded =
    (value): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setCallEnded(value));
        };

export const setCallVideoEnded =
    (value): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setCallVideoEnded(value));
        };

export const setRemoteStream =
    (stream): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setRemoteStream(stream));
        };

// export const setCall =
//   (call): AppThunk =>
//   async (dispatch) => {
//     dispatch(slice.actions.setCall(call));
//   };

export const setVideoCall =
    (call): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setVideoCall(call));
        };

export const setMyVideo =
    (myVideo: any): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setMyVideo(myVideo));
        };

export const setIsIncomingCall =
    (value): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setIsIncomingCall(value));
        };

export const setCallState =
    (value: CallState): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setCallState(value));
        };
export const setCallType =
    (value: CallType): AppThunk =>
        async (dispatch) => {
            dispatch(slice.actions.setCallType(value));
        };

export default slice;
