import {Suspense, lazy} from 'react';
import {Navigate} from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';

export const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );

const Users = Loader(lazy(() => import('src/content/configuration/Users')));
const Sites = Loader(lazy(() => import('src/content/configuration/Sites')));
const Messenger = Loader(lazy(() => import('src/content/provider/Messenger')));
const Specialities = Loader(lazy(() => import('src/content/configuration/Specialities')));
const Notifications = Loader(lazy(() => import('src/content/provider/Notifications')));

const configurationRoutes = [
    {
        path: '',
        element: <Navigate to="users" replace/>
    },
    {
        path: 'users',
        element: <Users/>
    },
    {
        path: 'sites',
        element: <Sites/>
    },
    {
        path: 'specialities',
        element: <Specialities/>
    },
    {
        path: 'messenger',
        element: <Messenger/>,
        state: {}
    },
    {
        path: 'notifications',
        element: <Notifications />,
        state: {}
    }
];

export default configurationRoutes;