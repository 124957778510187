import {useState, useEffect, memo} from 'react';
import {useDispatch, useSelector} from 'src/store';
import {
    IconButton,
    styled,
    Dialog,
    DialogTitle,
    Box,
    Tooltip,
    useTheme,
    DialogContent
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Close from '@mui/icons-material/Close';
import slice, {setIsIncomingCall} from '../../../../slices/messenger';
import {useStompClient, useSubscription} from 'react-stomp-hooks';
import ControlButtons from './controlButtons';
import DisplayMedia from './displayMedia';
import {getDeviceType} from "../../../../utils/utils";

const IconButtonWrapper = styled(IconButton)(
    ({theme}) => `
  padding: ${theme.spacing(0.1)};
  margin:0px;
  color: ${theme.palette.primary.contrastText};
  transform: translateY(0px);
  transition: ${theme.transitions.create(['color', 'transform', 'background'])};
  
  .MuiSvgIcon-root {
      transform: scale(1);
      transition: ${theme.transitions.create(['transform'])};
  }

  &:hover {
      background: initial;
      transform: translateY(-2px);

      .MuiSvgIcon-root {
          transform: scale(1.2);
      }
  }
`
);

const CallVideoComponent = memo(function CallVideoComponent() {
    const theme = useTheme();
    const {t}: { t: any } = useTranslation();
    const stompClient = useStompClient();
    const [pc, setPc] = useState<RTCPeerConnection | null>(null);
    const [ready, setReady] = useState(false);
    const {user} = useSelector((state) => state.auth);
    const {
        userSelected,
        callType,
        currentAppointment,
        sessionId,
        room,
        isCaller
    } = useSelector((state) => state.messenger);
    const [startTime, setStartTime] = useState(null);

    const [isLocalAudioMuted, setIsLocalAudioMuted] = useState(true);
    const [isLocalVideoMuted, setIsLocalVideoMuted] = useState(
        callType == 'video'
    );
    const dispatch = useDispatch();

    const [isRemoteAudioMuted, setIsRemoteAudioMuted] = useState(true);
    const [isRemoteVideoMuted, setIsRemoteVideoMuted] = useState(callType == 'video');
    const [localStream, setLocalStream] = useState<MediaStream | null>(null);
    const [remoteStream, setRemoteStream] = useState<MediaStream | null>(null);
    const constraints = {audio: true, video: true};
    let makingOffer;
    const rtcConfig = {
        iceServers: [
            {
                urls: 'stun:stun.alphacron.de:3478'
            },
            {
                urls: 'stun:stun1.l.google.com:19302'
            },
            {
                urls: 'stun:stun2.l.google.com:19302'
            },
            {
                urls: 'stun:stun3.l.google.com:19302'
            },
            {
                urls: 'stun:stun4.l.google.com:19302'
            }
        ]
    };

    //Important function.
    const readEvent = (e, deserialize: boolean = true) =>
        deserialize ? JSON.parse(e.body) : e.body;
    const topic = (name: string) => `/topic/v2/${name}`;
    const sendEvent = (name: string, body: any, serialize: boolean = true) => {
        stompClient?.publish({
            destination: `/app/v2/${name}`,
            body: serialize ? JSON.stringify(body) : body || ''
        });
    };
    const logError = (name: string, error: any) =>
        console.log(`Error in ${name}: => ${error}`);

    async function start() {
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            setLocalStream(stream);
        } catch (err) {
            logError('MediaStream', err);
        }
    }

    const shareStream = (stream: MediaStream) => {
        pc.addTrack(stream.getTracks()[0], stream);
        if (callType == "video")
            pc.addTrack(stream.getTracks()[1], stream);
    };

    const negotiationneeded = async (e) => {
        try {
            makingOffer = true;
            await pc.setLocalDescription();
            sendEvent(`rtcData/${sessionId}/${room}`, {
                sdp: pc.localDescription
            });
        } catch (error) {
            logError('Negotiation needed', error);
        } finally {
            makingOffer = false;
        }
    };

    const iceconnectionstatechange = () => {
        if (pc.iceConnectionState === 'failed') {
            pc.restartIce();
        }
    };

    const track = ({track, streams}) => {
        track.onunmute = () => {
            if (remoteStream != null) {
                return;
            }
            setRemoteStream(streams[0]);
        };
    };

    const icecandidate = ({candidate}) =>
        sendEvent(`rtcData/${sessionId}/${room}`, candidate);

    useEffect(() => {
        if (pc != null) {
            pc.onnegotiationneeded = negotiationneeded;
            pc.oniceconnectionstatechange = iceconnectionstatechange;
            pc.ontrack = track;
            pc.onicecandidate = icecandidate;
        }
    }, [pc, room]);

    useEffect(() => {
        setPc(new RTCPeerConnection(rtcConfig));
        start().then(() => {
            setReady(true);
        });
    }, []);

    const makeCall = () => {
        const deviceID = getDeviceType();
        if (userSelected?.id === currentAppointment?.creator) {
            sendEvent(
                `requestRoom/${sessionId}`,
                {
                    callee: userSelected.id,
                    from: user.id,
                    callType: callType,
                    appointmentId: currentAppointment.id,
                    deviceID
                },
                true
            );
        } else {
            sendEvent(
                `requestRoom/${sessionId}`,
                {
                    callee: userSelected.id, from: user.id, callType: callType,
                    deviceID
                },
                true
            );
        }
        dispatch(slice.actions.setCallState('outgoing'));
        dispatch(slice.actions.audioRefAction({event: 'play', type: 'call'}));
    };

    useSubscription(topic(`responseRoom/${sessionId}`), async (e) => {
        dispatch(slice.actions.setRoom(readEvent(e, false)));
    });


    const answerCall = () => {
        sendEvent(`acceptRoom/${sessionId}`, {room, deviceID: getDeviceType()}, true);
    };
    const leaveCall = () => {
        dispatch(slice.actions.audioRefAction({event: 'stop', type: 'call'}));
        sendEvent(`leaveRoom/${room}`, null);
        resetData();
    };

    const resetData = () => {
        dispatch(setIsIncomingCall(false));
        if (remoteStream) {
            remoteStream.getTracks().forEach((track) => track.stop());
        }
        if (localStream) {
            localStream.getTracks().forEach((track) => track.stop());
        }
        setStartTime(false);
        setLocalStream(null);
        setRemoteStream(null);
        dispatch(slice.actions.setDialogCall(false));
        dispatch(slice.actions.setUserSelected(null));
        dispatch(slice.actions.resetStateMessenger());
        dispatch(slice.actions.setRemoteStream(null));
        dispatch(slice.actions.setRemoteDevice(null));
        dispatch(slice.actions.setCallState("new"));
        dispatch(slice.actions.setRoom(null));
        if (pc) {
            pc.ontrack = null;
            pc.onicecandidate = null;
            pc.oniceconnectionstatechange = null;
            pc.onsignalingstatechange = null;
            pc.onicegatheringstatechange = null;
            pc.onnegotiationneeded = null;
            pc.close();
        }
    }

    useSubscription(topic(`roomJoined/${room}`), async (e) => {

        dispatch(slice.actions.audioRefAction({event: 'stop', type: 'call'}));
        dispatch(slice.actions.setCallState('answered'));
        setStartTime(Date.now());
        if (isCaller) {
            shareStream(localStream);
            const remoteDevice: "mobile" | "tablet" | "desktop" = readEvent(e, false)
            dispatch(slice.actions.setRemoteDevice(remoteDevice));
        }
    });

    useSubscription(topic(`roomFull/${room}`), async (e) => {
        console.log('Room: ', readEvent(e, false), ' is full', room);
    });


    useSubscription(topic(`rtcData/${sessionId}`), async (e) => {
        let sdp, candidate;
        const data = readEvent(e);
        if (data != null) {
            if ('sdp' in data) sdp = data.sdp;
            else if ('candidate' in data) candidate = data;
            if ('audio' in data) setIsRemoteAudioMuted(data.audio);
            if ('video' in data) setIsRemoteVideoMuted(data.video);
        }

        try {
            if (sdp && sdp != null) {
                const offerCollision =
                    sdp.type === 'offer' &&
                    (makingOffer || pc.signalingState !== 'stable');
                if (offerCollision) {
                    return;
                }
                await pc.setRemoteDescription(sdp);
                if (sdp.type === 'offer') {
                    try {
                        shareStream(localStream);
                        await pc.setLocalDescription();
                        sendEvent(`rtcData/${sessionId}/${room}`, {
                            sdp: pc.localDescription
                        });
                    } catch (error) {
                        logError('Create answer', error);
                    }
                }
            } else if (candidate) {
                pc.addIceCandidate(candidate);
            }
        } catch (err) {
            logError('RTC Data', err);
        }
    });


    const handleMuteUnmuteAudio = () => {
        const toggle = !localStream.getAudioTracks()[0].enabled;
        sendEvent(`rtcData/${sessionId}/${room}`, {audio: toggle});
        setIsLocalAudioMuted(toggle);
        localStream.getAudioTracks()[0].enabled = toggle;
    };
    const handleMuteUnmuteVideo = () => {
        const toggle = !localStream.getVideoTracks()[0].enabled;
        sendEvent(`rtcData/${sessionId}/${room}`, {video: toggle});
        setIsLocalVideoMuted(toggle);
        localStream.getVideoTracks()[0].enabled = toggle;
    };

    return (
        <Dialog fullWidth maxWidth="lg" open={true} key={'bottom'}>
            <DialogTitle
                sx={{
                    p: 0.5,
                    background: theme.colors.gradients.orange3
                }}
            >
                <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
                    <Tooltip arrow title={t('Close')}>
                        <IconButtonWrapper
                            size="small"
                            onClick={leaveCall}
                            sx={{
                                '&:hover': {background: `${theme.colors.error.light}`}
                            }}
                        >
                            <Close/>
                        </IconButtonWrapper>
                    </Tooltip>
                </Box>
            </DialogTitle>

            <DialogContent
                sx={{
                    background: theme.colors.alpha.white[100]
                }}
            >
                <DisplayMedia
                    localStream={localStream}
                    remoteStream={remoteStream}
                    isLocalAudioMuted={isLocalAudioMuted}
                    isLocalVideoMuted={isLocalVideoMuted}
                    isRemoteAudioMuted={isRemoteAudioMuted}
                    isRemoteVideoMuted={isRemoteVideoMuted}
                />
                <ControlButtons
                    makeCall={makeCall}
                    answerCall={answerCall}
                    leaveCall={leaveCall}
                    isCaller={isCaller}
                    isReady={ready}
                    startTime={startTime}
                    isLocalAudioMuted={isLocalAudioMuted}
                    isLocalVideoMuted={isLocalVideoMuted}
                    handleMuteUnmuteAudio={handleMuteUnmuteAudio}
                    handleMuteUnmuteVideo={handleMuteUnmuteVideo}
                    callType={callType == 'audio' ? 'audio' : 'video'}
                />
            </DialogContent>
        </Dialog>
    );
});

export default CallVideoComponent;
