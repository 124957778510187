import ReactDOM from 'react-dom';
import 'src/mocks';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';
import adapter from 'webrtc-adapter';
import 'webrtc-adapter';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import store from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { StompHeaders, StompSessionProvider } from 'react-stomp-hooks';
import { getAuthorizationHeader } from './utils/http';
import CallProvider from './slices/call';

const headers = {
  Authorization: getAuthorizationHeader()
};
ReactDOM.render(
  <StompSessionProvider
    url={"https://teledok.ht/ws"}
    // url={'http://172.20.10.4:8080/ws'}
    debug={(str) => {
      // console.log(str);
    }}
    connectHeaders={headers}
    onConnect={(d) => {
      // console.log("connected...",)
    }}
  >
    <HelmetProvider>
      <Provider store={store}>
        <CallProvider>
          <SidebarProvider>
            <HashRouter>
              <ScrollTop />
              <App />
            </HashRouter>
          </SidebarProvider>
        </CallProvider>
      </Provider>
    </HelmetProvider>
  </StompSessionProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
