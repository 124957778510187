import {
    Box,
    Card,
    styled,
    useTheme,
    Grid,
    CardActionArea,
    CardMedia,
    Typography,
    alpha,
    Avatar
} from '@mui/material';
import MicNoneTwoToneIcon from '@mui/icons-material/MicNoneTwoTone';
import MicOffTwoToneIcon from '@mui/icons-material/MicOffTwoTone';
import {Fragment} from 'react';
import useAuth from 'src/hooks/useAuth';
import {downloadFile} from 'src/service/file-service';
import {useSelector} from '../../../../store';
import {getDeviceType} from "../../../../utils/utils";

const ThemeSettingsButton = styled(Box)(
    ({theme}) => `
          position: relative
          &::before {
              width: 60px;
              height: 60px;
              content: ' ';
              position: absolute;
              border-radius: 100px;
              left: 99px;
              top: 85px;
              background: ${theme.colors.alpha.white[100]};
              animation: ripple 1 infinite;
              transition: ${theme.transitions.create(['all'])};
          }

          .MuiSvgIcon-root {
            animation: pulse 1s infinite;
            transition: ${theme.transitions.create(['all'])};
          }
  `
);

const CardActions = styled(Box)(
    ({theme}) => `
    position: absolute;
    right: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};
    z-index: 7;
    display: flex;
  `
);

const LabelWrapper = styled(Box)(
    ({theme}) => `
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    font-weight: bold;
    line-height: 23px;
    height: 22px;
    padding: ${theme.spacing(0, 1.2)};
    border-radius: 50px;
  `
);

const CardActionAreaWrapper = styled(CardActionArea)(
    () => `
    display: flex;
    width:100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .MuiTouchRipple-root {
        opacity: .3;
    }
    &:hover {
        .MuiCardActionArea-focusHighlight {
            opacity: .3;
        }
    }
  `
);

const CardActionAreaWrapperMobile = styled(CardActionArea)(
    () => `
     height: 750px;
     margin: auto;
  `
);

const CardActionAreaWrapperTablette = styled(CardActionArea)(
    () => `
     height: 750px;
     margin: auto;
  `
);

const CardActionAreaWrapperPC = styled(CardActionArea)(
    () => `
      max-height: 750px;
     width:100%;
     margin: auto;
  `
);

interface DisplayMediaProps {
    localStream: MediaStream | null;
    remoteStream: MediaStream | null;
    isLocalAudioMuted: boolean;
    isLocalVideoMuted: boolean;
    isRemoteAudioMuted: boolean;
    isRemoteVideoMuted: boolean;
}

export default function DisplayMedia({
                                         localStream,
                                         remoteStream,
                                         isLocalAudioMuted,
                                         isLocalVideoMuted,
                                         isRemoteAudioMuted,
                                         isRemoteVideoMuted
                                     }: DisplayMediaProps) {
    const theme = useTheme();
    const {user} = useAuth();
    const {userSelected, callType, remoteDevice} = useSelector((state) => state.messenger);
    const currentDevice = getDeviceType();

    return (
        <Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                    <Box p={2}>
                        <Card
                            sx={{
                                textAlign: 'center',
                                background: theme.colors.info.dark,
                                transition: `${theme.transitions.create([
                                    'box-shadow',
                                    'transform'
                                ])}`,
                                transform: 'translateY(0px)',
                                '&:hover': {
                                    transform: 'translateY(-5px)',
                                    boxShadow: `0 1rem 2rem 0 ${alpha(
                                        theme.colors.alpha.black[100],
                                        0.1
                                    )},
                                0 0.1rem 1.6rem ${alpha(
                                        theme.colors.alpha.black[100],
                                        0.2
                                    )},
                                0 0.1rem 0.2rem ${alpha(
                                        theme.colors.alpha.black[100],
                                        0.15
                                    )}`
                                }
                            }}
                        >
                            <CardActionAreaWrapper>
                                {(remoteStream == null ||
                                    callType == 'audio' ||
                                    !isRemoteVideoMuted) && (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            textAlign: 'center',
                                            pt: 6,
                                            pb: 3,
                                            mx: 'auto'
                                        }}
                                    >
                                        <Avatar
                                            sx={{
                                                mx: 'auto',
                                                mb: 1.5,
                                                width: 200,
                                                height: 200,
                                                border: `${theme.colors.alpha.white[100]} solid 4px`,
                                                boxShadow: `0 0 0 3px ${theme.colors.primary.main}`
                                            }}
                                            src={downloadFile(userSelected?.person?.picture)}
                                        />
                                        <Typography
                                            gutterBottom
                                            variant="h3"
                                            sx={{color: `${theme.colors.alpha.white[100]}`}}
                                        >
                                            {userSelected?.person?.fullname}
                                        </Typography>
                                    </Box>
                                )}
                                {remoteStream != null && (!isRemoteVideoMuted || callType == "audio")?
                                           <video
                                               id={'remoteStream'}
                                               playsInline
                                               style={{
                                                   margin: 0,
                                                   alignSelf: '',
                                                   width:  0,
                                                   height:  0
                                               }}
                                               ref={(videoElement) => {
                                                   if (videoElement) videoElement.srcObject = remoteStream;
                                               }}
                                               autoPlay
                                           /> :
                                    (remoteStream != null && currentDevice == "mobile" ? <video
                                        id={'remoteStream'}
                                        playsInline
                                        style={{
                                            margin: 'auto',
                                            alignSelf: 'center',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: "contain"
                                        }}
                                        ref={(videoElement) => {
                                            if (videoElement) videoElement.srcObject = remoteStream;
                                        }}
                                        autoPlay
                                    /> :remoteStream != null && remoteDevice == "mobile" ?
                                        <CardActionAreaWrapperMobile>
                                            <video
                                                id={'remoteStream'}
                                                playsInline
                                                style={{
                                                    margin: 'auto',
                                                    alignSelf: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: "contain"
                                                }}
                                                ref={(videoElement) => {
                                                    if (videoElement) videoElement.srcObject = remoteStream;
                                                }}
                                                autoPlay
                                            />
                                        </CardActionAreaWrapperMobile>
                                        : remoteStream != null && remoteDevice == "tablet" ?
                                            <CardActionAreaWrapperTablette>
                                                <video
                                                    id={'remoteStream'}
                                                    playsInline
                                                    style={{
                                                        margin: 'auto',
                                                        alignSelf: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: "contain"
                                                    }}
                                                    ref={(videoElement) => {
                                                        if (videoElement) videoElement.srcObject = remoteStream;
                                                    }}
                                                    autoPlay
                                                />
                                            </CardActionAreaWrapperTablette>
                                            : remoteStream != null && remoteDevice == "desktop" ?
                                                <CardActionAreaWrapperPC>
                                                    <video
                                                        id={'remoteStream'}
                                                        playsInline
                                                        style={{
                                                            margin: 'auto',
                                                            alignSelf: 'center',
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: "contain"
                                                        }}
                                                        ref={(videoElement) => {
                                                            if (videoElement) videoElement.srcObject = remoteStream;
                                                        }}
                                                        autoPlay
                                                    />
                                                </CardActionAreaWrapperPC>
                                                : null
                                    )}
                                <CardActions
                                    sx={{
                                        bottom: 'auto',
                                        top: `${theme.spacing(2)}`
                                    }}
                                >
                                    <LabelWrapper
                                        sx={{
                                            mx: 1,
                                            color: `${theme.colors.alpha.white[100]}`
                                        }}
                                    >
                                        {!isRemoteAudioMuted ? (
                                            <MicOffTwoToneIcon fontSize="small"/>
                                        ) : (
                                            <MicNoneTwoToneIcon fontSize="small"/>
                                        )}
                                    </LabelWrapper>
                                </CardActions>
                            </CardActionAreaWrapper>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <Box p={2}>
                                <Card
                                    sx={{
                                        textAlign: 'center',
                                        background: theme.colors.info.dark,
                                        transition: `${theme.transitions.create([
                                            'box-shadow',
                                            'transform'
                                        ])}`,
                                        transform: 'translateY(0px)',

                                        '&:hover': {
                                            transform: 'translateY(-10px)',
                                            boxShadow: `0 1rem 4rem 0 ${alpha(
                                                theme.colors.alpha.black[100],
                                                0.1
                                            )}, 
                                0 0.1rem 1.6rem ${alpha(
                                                theme.colors.alpha.black[100],
                                                0.2
                                            )}, 
                                0 0.1rem 0.2rem ${alpha(
                                                theme.colors.alpha.black[100],
                                                0.15
                                            )}`
                                        }
                                    }}
                                >
                                    <CardActionAreaWrapper>
                                        {!isLocalVideoMuted && (
                                            <ThemeSettingsButton
                                                sx={{
                                                    position: 'relative',
                                                    textAlign: 'center',
                                                    pt: 6,
                                                    pb: 3,
                                                    mx: 'auto'
                                                }}
                                            >
                                                <Avatar
                                                    sx={{
                                                        mx: 'auto',
                                                        mb: 1.5,
                                                        width: 114,
                                                        height: 114,
                                                        border: `${theme.colors.alpha.white[100]} solid 4px`,
                                                        boxShadow: `0 0 0 3px ${theme.colors.error.main}`
                                                    }}
                                                    src={downloadFile(user?.person?.picture)}
                                                />
                                                <Typography
                                                    gutterBottom
                                                    variant="h3"
                                                    sx={{color: `${theme.colors.alpha.white[100]}`}}
                                                >
                                                    {user?.person?.fullname}
                                                </Typography>
                                            </ThemeSettingsButton>
                                        )}
                                        <CardMedia
                                            id={'localStream'}
                                            component="video"
                                            playsInline
                                            style={{
                                                height: isLocalVideoMuted ? '100%' : 0,
                                                width: isLocalVideoMuted ? '100%' : 0
                                            }}
                                            ref={(videoElement) => {
                                                if (videoElement) videoElement.srcObject = localStream;
                                            }}
                                            autoPlay
                                            muted
                                        />
                                        <CardActions
                                            sx={{
                                                bottom: 'auto',
                                                top: `${theme.spacing(2)}`
                                            }}
                                        >
                                            <LabelWrapper
                                                sx={{
                                                    color: `${theme.colors.alpha.white[100]}`
                                                }}
                                            >
                                                {!isLocalAudioMuted ? (
                                                    <MicOffTwoToneIcon fontSize="small"/>
                                                ) : (
                                                    <MicNoneTwoToneIcon fontSize="small"/>
                                                )}
                                            </LabelWrapper>
                                        </CardActions>
                                    </CardActionAreaWrapper>
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    );
}
