const frJSON = {
    'Welcome to SEROvie! Setup your account.': 'Bienvenue sur SEROvie! Configurez votre compte',
    'Fill in the fields below to sign up for an account.': 'Remplissez les champs ci-dessous pour créer votre compte.',
    'Already have an account?': 'Vous avez déjà un compte?',
    'Sing in': 'S\'identifier',
    'The givename field is required': 'Le champ prénom est obligatoire',
    'The lastname field is required': 'Le champ nom est obligatoire',
    'Email already exist': 'L\'adresse email existe déjà',
    'The email provided should be a valid email address': 'L\'adresse email doit être valide',
    'The email field is required': 'Le champ adresse email est obligatoire',
    'The name field is required': 'Le champ nom d\'utilisateur est obligatoire',
    'Password must have minimum 8 characters': 'Le mot de passe doit contenir au moins 8 caractères',
    'Password must not exceed 50 characters': 'Le mot de passe ne doit pas dépasser 50 caractères',
    'Password format is incorrect': 'Format de mot de passe incorrect',
    'Passwords are not identical': 'Les mots de passe ne sont pas identiques',
    'Confirm Password is required': 'Le champ Confirmer le mot de passe est obligatoire',
    'Password is required': 'Le champ mot de passe est obligatoire',
    'Username Required': 'Le champ du nom d\'utilisateur est obligatoire',
    'Username required more than 3 characters': 'Le nom d\'utilisateur doit comporter plus de 3 caractères',
    'Username already exist': 'Le nom d\'utilisateur existe déjà',
    'Connect my account with': 'Connecter mon compte à',
    'Sign up': 'S\'inscrire',
    'Given Name': 'Prénom',
    'Last Name': 'Nom',
    'Username': 'Nom d\'utilisateur',
    'Email address': 'Adresse email',
    'Confirm Password': 'Confirmer mot de passe',
    'The username field is required': 'Le nom d\'utilisateur est obligatoire',
    'The password field is required': 'Le mot de passe est obligatoire',
    'Configuration': 'Configuration',
    'User profile': 'Profil utilisateur',
    'User status': 'Statut de l\'utilisateur',
    'User List': 'Liste des utilisateurs',
    'Users Management': 'Gestion des utilisateurs',
    'Users Management Message Header': 'Ici, nous traitons avec tous les utilisateurs du système.',
    'Create user': 'Créer un utilisateur',
    'Administrator': 'Administrateur',
    'Admin System': 'Administrateur du Système',
    'Adminnistration': 'Administration',
    'Manage Users': 'Gestion des utilisateurs',
    'Manage Counseling': 'Gestion des conseils',
    'Counseling': 'Conseils',
    'Counseling List': 'Liste de conseils',
    'Login': "S'authentifier",
    'Password': "Mot de passe",
    "Forgotten Password ?": "Mot de passe oublié?",
    "Sign in": "Se connecter",
    "Don’t have an account yet? ": "Vous n'avez pas encore de compte? ",
    "Register here": "S'inscrire ici",
    "Fill in the fields below to sign into your account.": "Remplissez les espaces ci-dessous pour vous connecter",
    "Reset password": "Restaurer mot de passe",
    "Delete selected counseling": "Supprimer le(s) conseil(s) selectionné(s)",
    "This action is not reversible and will delete all selected counseling(s).": "Cette action est irréversible et supprimera tous les conseils selectionnés",
    "Are you sure to want to continue ?": "Voulez-vous vraiment continuer ?",
    "Delete counseling": "Supprimer un conseil",
    "Cancel": "Annuler",
    "Delete": "Supprimer",
    'Postpone publication': 'Publier plutard',
    'Status': 'Statut',
    'Name': 'Nom',
    'Publication date': 'Date',
    'Preview': 'Aperçu',
    'Save draft': 'Brouillon',
    'Publish': 'Publier',
    'Add image': 'Ajouter une image',
    'Drop the files to start uploading': 'Draguer une image ici',
    'Project title here...': 'Le titre de votre texte ici...',
    'Your counseling text body here': 'Le titre de votre conseil ici...',
    'Create new topic': 'Créer un nouveau sujet',
    'Fill in the fields below to create a new topic': 'Remplissez les espaces vide pour créer un nouveau sujet',
    'Counselings Management': 'Gestion des conseils',
    'Here we manage all about counselings': 'Ici nous gérons tout sur les conseils',
    'Edit counseling': 'Modifier conseil',
    'We couldn\'t find any counseling matching your search criteria': 'Aucun résultat ne correspond à votre recherche',
    'f': 'Féminin',
    'm': 'Masculin',
    'i': 'Inconnu',
    'Phone': 'Téléphone',
    'Select the locale for user': 'Sélectionnez la langue de l\'utilisateur',
    'Select the profile for user': 'Sélectionnez le profil de l\'utilisateur',
    'Add new user': 'Ajoutez un nouvel utilisateur',
    'Provider': 'Préstataire',
    'Home': 'Accueil',
    'Agenda': 'Agenda',
    'Messenger': 'Messagerie',
    'messenger': 'Messagerie',
    'Manage Officier': 'Officier de données',
    'Form': 'Formulaire',
    'List': 'Liste',
    'Meetings and Appointement': 'Rencontre et Rendez-vous',
    'Check out the latest meetings and meetings in your calendar': 'Vérifiez vos derniers rencontres et rendez-vous dans votre calendrier',
    'The event has been deleted': 'Rendez-vous annulé',
    'The end date should be after start date': 'La date de fin doit être après la date de début',
    'The calendar has been successfully updated!': 'Le calendrier a été mis à jour avec succès!',
    'Create new calendar event': 'Créez un nouvel évènement',
    'Edit calendar event': 'Modifier un évènement dans le calendrier',
    'Select your event type': 'Sélectionnez le type d\'évènement',
    'Select prefered work day': 'Sélectionnez vos jours de travail préféré',
    'All days': 'Tous les jours',
    'Event end date': 'Date de fin d\'évènement',
    'Calendar': 'Calendrier',
    'Appointment is pending': 'Rendez-vous en attente',
    'Appointment expired': 'Rendez-vous expiré',
    'Appointments': 'Rendez-vous',
    'appointment': 'Rendez-vous',
    'Welcome Dr': 'Bienvenue Dr',
    'Since your last login there were': 'Depuis votre dernière connection il y\'a',
    'new patients appointments': 'nouveau(x) rendez-vous',
    'and': 'et',
    'new messages': 'nouveau(x) message(s)',
    'Decline': 'Décliner',
    'Confirm': 'Confirmer',
    'You confirmed this Appointment successfuly!': 'Vous avez confirmé ce rendez-vous avec succès.',
    'You declined this Appointment.': 'Vous avez décliné ce rendez-vous!',
    'You canceled this appointment.': 'Vous avez annulé ce rendez-vous.',
    'Appointment Confirmation': 'Confirmation de rendez-vous',
    'Once you click on the YES button, this person will be added to your appointment list for consultation later. Do you really want to confirm ?': 'Une fois que vous cliquez sur le bouton OUI ,' +
        'cette personne sera ajoutée à votre liste de ' +
        'rendez-vous pour pouvoir être consulté plus tard.' +
        'Voulez-vous vraiment confirmer?',
    'Appointment declination': 'Déclinaison de rendez-vous',
    'Once click on the YES button, this person will be removed from your list of confirmation. This action is irreversible. Do you really want to decline ?':
        'Une fois que vous cliquez sur le bouton OUI ,' +
        'cette personne sera supprimée de votre liste de ' +
        'confirmation. Cette action est irréversible.' +
        'Voulez-vous vraiment décliner?',
    'Declined': 'Déclinée',
    'Pending': 'En attente',
    'Confirmed': 'Confirmée',
    'Expired': 'Expirée',
    'All': 'Toutes',
    'Type': 'Type',
    'Motivation': 'Motivation',
    'Request an appointment': 'Demande de rendez-vous',
    'Yes': 'Oui',
    'No': 'Non',
    'Filters': 'Filtrer',
    'Search appointement...': 'Rechercher un rendez-vous...',
    'Today work hour': 'Heure de travail',
    'You have no consultation(s) yet!': 'Vous n\'avez pas encore de consultations',
    'Your consulations': 'Vos consulations',
    'You are about to start a consultation for this patient.': 'Vous êtes sur le point de démarrer une consultation pour ce patient.',
    'When you click on the start button, you will be redirected to the consultation mode indicated.': 'Lorsque vous cliquez sur le bouton commencer, vous serez rediriger vers le mode de consultation indiqué.',
    'Make sure you have isantePLUS open in your browser before starting your consultation.': 'Assurez vous d\'avoir isantePLUS ouvert dans votre navigateur avant de commencer votre consultation.',
    'Start of consultation': 'Démarrage de consultation',
    'Start': 'Commencer',
    'Consultation': 'Consultation',
    'Reason for consultation': 'Motif de consultation',
    'Search online people...': 'Rechercher personne en ligne...',
    'View all': 'Voir tout',
    'Update Speciality': 'Modifier une spécialité',
    'New Speciality': 'Nouvelle spécialité',
    'Fill in the fields below to': 'Remplissez les champs ci-dessous pour',
    'update': 'modifier',
    'create': 'créer',
    'a speciality': 'une spécialité',
    'The speciality name is required': 'Le nom de la spécialité est obligatoire',
    'The speciality description is required': 'La décription de la spécialité est obligatoire',
    'Speciality': 'Spécialité',
    'Description': 'Description',
    'Save': 'Enregistrer',
    'The speciality(ies) has been deleted successfully': 'La ou les spécialité (s) a (ont) été supprimée (s) avec succès',
    'The speciality(ies) deletion failed': 'La suppression de la (des) spécialité (s) a échouée',
    'The speciality was added successfully': 'La spécialité à été ajoutée avec succès!',
    'Delete speciality(ies)': 'Supprimer specialité (s)',
    'Manage Specialities': 'Gestion des Spécialités',
    'Speciality Management': 'Gestion de spécialités',
    'Add speciality': 'Ajouter une specialité',
    'Search by name or description...': 'Rechercher par nom ou description...',
    'We couldn\'t find any speciality matching your search criteria': 'Nous n\'avons pas trouver de spécialité correspondant à votre critère',
    'Creator': 'Créateur',
    'Created date': 'Date de création',
    'Editor': 'Éditeur',
    'Edited date': 'Date de modification',
    'Action': 'Action',
    'View': 'Vue',
    'Speciality already exist': 'Cette spécialité existe déja.',
    'counseling(s)': 'conseil(s)',
    'All counseling': 'Tous les conseils',
    'Published counseling': 'Conseil(s) publié',
    'published': 'Publié',
    'draft': 'Brouillon',
    'Pending counseling': 'Conseil(s) en attente',
    'In draft': 'Dans brouillon',
    'Counseling published for this month': 'Conseils publiés pour ce mois',
    'Check how many counseling are publiseh per day for the current month': 'Regardez combien de conseils sont publiés par jour pour ce mois',
    'Create counseling': 'Nouveau conseil',
    'delivered': 'livré',
    'viewed': 'vue',
    'Today targets reached': 'Objectifs atteints aujourd\'hui',
    'These are your performance indicators on delivered counseling view': 'Ce sont vos indicateurs de performance sur la vue des conseils délivrés',
    'Delivered view': 'Vue sur livré',
    'Viewed view': 'vue sur lu',
    'Go to counselings home': 'Vers tableau de bord des conseils',
    'Add new counseling': 'Ajouter un conseil',
    'New password': 'Nouveau mot de passe',
    'Enter the email used for registration to reset your password.': 'Entrez l\'adresse email de votre compte pour restaurer le mot de passe.',
    'Want to try to sign in again?': 'Voulez-vous vous reconnecter?',
    'The password reset instructions have been sent to your email': 'Les instructions de réinitialisation du mot de passe ont été envoyées à votre email',
    'Check your email for further instructions': 'Veuillez consulter votre email pour suivre les instructions',
    'Continue to login': 'Restez connecter',
    'Failed to recovery your account, email not found or time out': 'Récuperation de compte échouée email non trouvée ou mauvaise connexion d\'internet',
    'Changed Password': 'Changer le mot de passe',
    'Fill the fields below to provide a new personal password.': 'Remplir les champs ci-dessous pour changer le mot de passe',
    'Change password': 'Changer le mot de passe',
    'Password format not match': 'Format incorrect, il faut au moins une lettre majuscule ou un chiffre',
    'Pasword is not identic': 'Les mots de passe ne sont pas identiques',
    'The password has been changed successful': 'Le mot de passe a été modifié avec succès',
    'You can sign in now': 'Vous pouvez vous connecter maintenant',
    'Select speciality': 'Sélectionnez votre spécialité',
    'Fill in the fields below to create and add a new user to the site': 'Remplissez la forme suivante pour créer un nouveau utilisateur',
    'Select profile': 'Selectionnez votre profil',
    'Search by name, email or username...': 'Rechercher par nom, email ou nom d\'utilisateur...',
    'We couldn\'t find any users matching your search criteria': 'Nous ne pouvons pas trouver d\'utilisateurs correspondants a votre recherche',
    'Fullname': 'Nom complet',
    'Language': 'Langue',
    'Actions': 'Action',
    'All users': 'Tous les utilisateurs',
    'Email': 'Email',
    'pending': 'Attente',
    'active': 'Actif',
    'inactive': 'Inactif',
    'locked': 'Bloqué',
    'System administrator': 'Administrateur système',
    'Care recipient': 'Bénéficiaire de soin',
    'Care provider': 'Prestataire de soin',
    'Rows per page': 'Lignes par page',
    'Select user profile': 'Sélectionnez le profil de l\'utilisateur',
    'Select user locale': 'Sélectionnez une langue pour l\'utilisateur',
    'Update user': 'Modifiez l\'utilisateur',
    'Counselings Dashboard': 'Tableau de bord des conseils',
    'Profile settings': 'Paramètres de profil',
    'Sign out': 'Déconnecter',
    'Manage Sites': 'Gestion des sites',
    'Address': 'Adresse',
    'Location': 'Région',
    'Search by name, address or location...': 'Rechercher par nom, adresse ou région...',
    'Manage Site': 'Gestion des sites',
    'Site Management Message Header': 'Ici nous gérons tout à propos de la gestion des sites',
    'Create site': 'Créer un nouveau site',
    'Close selected site': 'Fermer le site selectionné',
    'We couldn\'t find any site matching your search criteria': 'Nous n\'avons trouvé aucun site correspondant à vos critères de recherches',
    'Edit site': 'Modifier un site',
    'Bulk actions': 'Liste actions',
    'Enable': 'Activer',
    'Disable': 'Desactiver',
    'Profile Update': 'Modifier le profil',
    'Status user': 'Modifier le statut',
    'Work time': 'Heure de travail',
    'The user account was updated successfully': 'L\'utilisateur a été mis à jour avec succès',
    'The user account was created successfully': 'L\'utilisateur a été créé avec succès',
    'Start call': 'Démarrer l\'appel',
    'Call type': 'Type d\'appel',
    'Video': 'Vidéo',
    'Call Video': 'Appel en vidéo',
    'Call Audio': 'Appel vocal',
    'User profil information': 'Information de profil',
    'Personal Information': 'Détail du contact',
    'Shared Files': 'Fichier partagé',
    'Chat': 'Messagerie',
    'Meetings': 'Rencontres',
    'You have no meetings!': 'Vous n\'avez pas de rencontres',
    'Files': 'Fichiers',
    'Online': 'En ligne',
    'To day': 'Aujourd\'hui',
    'Write your message here...': 'Tapez votre message ici...',
    'Choose an emoji': 'Choisissez un émoticone',
    'Attach a file': 'Attacher un fichier',
    'Send voice': 'Envoyer une note vocale',
    'Select site location': 'Sélectionnez une région',
    'Search...': 'Recherche...',
    'Switch language': 'Changer de langue',
    'Notifications': 'Notifications',
    'Theme': 'Thème',
    'Event start date': 'Date de début d\'évènnement',
    'Select frequency': 'Sélectionnez la fréquence',
    'Add meeting': 'Confirmer la disponibilité',
    'Save modifications': 'Modifier disponibilité',
    'Go home': 'Aller à l\'accueil',
    'Another meeting is already defined in this time': 'Il y a déjà une autre planification pour cette heure',
    'Start date must be inferior to end date': 'L\'heure de début doit être inférieure par rapport à l\'heure de fin',
    'Start date and end date can\'t be same': 'L\'heure de début doit être differente de l\'heure de fin',
    'Mon': 'Lun',
    'Tue': 'Mar',
    'Wed': 'Mer',
    'Thu': 'Jeu',
    'Fri': 'Ven',
    'Sat': 'Sam',
    'Sun': 'Dim',
    'Repeat one': 'Répéter une fois',
    'Only this event': 'Cet évènement uniquement',
    'All this month': 'Tout ce mois',
    'All similar events': 'Tout les évènements apparentés',
    'Previous Day': 'Jour précédent',
    'Today': 'Aujourd\'hui',
    'Next Day': 'Jour suivant',
    'Month': 'Mois',
    'Week': 'Semaine',
    'Day': 'Jour',
    'Customize your consultation duration': 'Modifiez la durée de votre consultation',
    'Select your site location': 'Sélectionnez la région du site',
    'Gender': 'Genre',
    'Update profile': 'Modifier profil',
    'Change profil info': 'Changer les informations du profil',
    'Fill in the fields below to change information about your profile': 'Remplissez les champs ci-dessous pour changer les informations du profil',
    'Go back': 'Retour à l\'accueil',
    'Profile for': 'Le profil de ',
    'This is your profile page. Find all the informations about account and feel free to modify them': 'Voici votre page de profil. Trouvez toutes les informations relatives à votre compte et n\'hésitez pas à les modifier.',
    'Edit your profile': 'Modifiez votre profil',
    'Information': 'Information',
    'Security': 'Sécurité',
    'Personal Details': 'Informations personnelles',
    'Manage informations related to your personal details': 'Gérez vos informations personnelles',
    'Account Settings': 'Paramètres de compte',
    'Manage details related to your account': 'Gérez vos informations de compte',
    'Account type': 'Type de compte',
    'Account status': 'Statut du compte',
    'Professional information': 'Informations professionelles',
    'Manage details related to your associated email addresses': 'Gérez les détails relatifs à votre adresse email',
    'Manage details related to your professional profile': 'Gérez les détails relatifs à votre profil professionel',
    'Health center': 'Hôpital',
    'Spécialisation': 'Spécialisation',
    'Current Date': 'La date selectionnée est: ',
    'all': 'Toute',
    'Stay connected': 'Restez connecté',
    'You have': 'Vous avez',
    'Counseling reports dashboard': 'Tableau de bord des conseils',
    'Meetings and Appointments': 'Réunion et rendez-vous',
    'cette demande de rendez-vous a été cancel': "Cette demande de rendez-vous a été annulée",

    'End consultation': 'Termier la consultation',
    'Add patient to a category': 'Ajouter un patient à une catégorie',
    'Please don\'t forget to add the patient category.': 'S\'il vous plait n\'oubliyez pas de mettre une catégorie pour le patient',
    'When you click on the end button, you will close the consultation.': 'Lorsque vous cliquez sur le bouton de fin, vous clôturerez la consultation.',
    'You are about to end the consultation with the patient.': 'Vous êtes sur le point de terminer la consultation avec le patient.',
    'End of consultation': 'Fin de la consultation',
    'Stop consultation': 'Arrêter la consultation',
    'Stop Conversation': 'Arrêter la conversation',
    'Reset Conversation': 'Réinitialiser la conversation',
    'Start Conversation': 'Démarrer une conversation',
    'Please give the reason for your refusal': 'Merci de donner la raison de votre refus.',
    'The user profil was changed successfully': 'Le profil de l\'utilisateur à été modifié avec succès',
    'reconnecting': 'Reconnexion...',
    'profile': 'Profil',
    'No publications today': 'Pas de publications pour aujourd\'hui',

    'Publish for a specific category': 'Publier pour une catégorie spécifique',
    'Postpone counseling publication': 'Reporter la publication',
    'Go to counselings list': 'Aller vers la liste des conseils',
    'Refusal Reason': 'Raison de refus',
    'Refusal Reason Error': 'S\'il vous plait donner la raison avant de decliner ce rendez-vous.',
    'The account has been created successful':'Ce compte vient d\'être créé avec succès',
    'You will received an email when a administrator will validate your account': 'Vous allez recevoir un email quand un administrateur validera votre compte',
    'You will received an email that confirm your account is active': 'Vous allez recevoir un email qui confirme que votre compte est activé',
    'profileUpdatedbeneficiaire': 'Changement du profil en Bénéficiaire de soin',
    'profileUpdatedadmin': 'Changement du profil en Administrateur de système',
    'profileUpdatedofficier': 'Changement du profil en Officier de donnée',
    'profileUpdatedprestataire': 'Changement du profil en Prestataire de soin',
    'of': 'de',
    'users': 'utilisateur(s)',
    'updatedinactive': 'Desactivé compte',
    'updatedactive': 'Activé compte',
    'cant call offline':'Vous ne pouvez pas passer l\'appel, vous êtes hors connection maintenant',
    'offline trying to reconnect' :'Vous êtes hors connection, reconnection en cous',
    'Messenger - Applications': 'Chat application',
    'incommingCallVideo': 'C\'est un rendez de type vidéo',
    'incommingCallAudio': 'C\'est un rendez de type audio',
};

export default frJSON;