import {FC, ReactNode} from 'react';
import {Navigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import {Role, Status} from 'src/models/user';
import {useSelector} from "react-redux";
import {RootState} from "../../store";
import {Login} from "@mui/icons-material";

interface AuthenticatedProps {
    children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = ({children}) => {
    const {user, isLoading, isAuthenticated} = useSelector((state: RootState) => state.auth);
    if (!isAuthenticated)
        return <Navigate to="/account/login"/>;
    else if (location.hash == "#/account/changed-password" && user.status != Status.PENDING)
        return <Navigate to={user?.profile.page}/>;
    return <>{isLoading === false && children}</>;
};

Authenticated.propTypes = {
    children: PropTypes.node
};

export default Authenticated;
