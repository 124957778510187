import {Profile} from "./profile";
import {Site} from "./site";
import {Speciality} from "./speciality";

export interface Person {
  id?: string;
  firstname: string;
  lastname: string;
  fullname?: string;
  phone?: string;
  email?: string;
  picture?: string;
  gender?: string;
  image?: { path: string, id?: number };

}

export interface User {
    id?: string;
    username: string;
    password?: string;
    locale?: string;
    status?: string;
    profile?: Profile;
    site?: Site;
    token?: string;
    person: Person;
    coverImg?: string;
    online?: boolean;
    speciality?: Speciality;
    category?: string;
    exponentPushToken?: string;
}

export enum Status {
    ALL = 'all',
    ACTIVE = 'active',
    PENDING = "pending",
    CANCEL = "cancel",
    DECLINED = "declined",
    INACTIVE = "inactive",
    LOCKED = "locked",
    CONFIRMED = "confirmed",
    EXPIRED = "expired",
};

export enum AppointmentType {
    VIDEO = "video",
    CHAT = "text",
    CALL = "audio",
}

export enum Role {
    SUPER = 'super',
    ADMIN = 'admin',
    OFFICIER = 'officier',
    BENEFICIAIRE = 'beneficiaire',
    PRESTATAIRE = 'prestataire'
};

export enum NotificationType {
    MESSENGER = 'messenger',
    APPOINTMENT = 'appointment',
    ALL = "all"
};


export const locales = [
    {label: 'English', value: 'en'},
    {label: 'Français', value: 'fr'},
    {label: 'Kreyòl', value: 'ht'}
];

export enum Category {
    INCONNU= "Inconnu",
    HARSAH = "HARSAH",
    PS = "Professionnels de Sexe",
    TG = "Transgenre",
    UDI = "Utilisateur de drogues injectables",
    CPS = "Clients des Professionnels(les) de Sexe"
};

export enum CALL_STATE {
    isReadyAcceptedCall = "isReadyAcceptedCall",
    isReceivingCall="isReceivingCall",
    isCallAccepted= "isCallAccepted" ,
    isCallEnded = "isCallEnded",
}