import { FC, useContext, useRef, useState } from 'react';

import {
    alpha,
    IconButton,
    useTheme,
    styled,
    Tooltip} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'src/theme/ThemeProvider';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';


const IconButtonWrapper = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

const HeaderTheme: FC = () => {
    const ref = useRef<any>(null);
    const { t }: { t: any } = useTranslation();
    const theme = useTheme();
    const setThemeName = useContext(ThemeContext);
    const currentThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
    const [themeChanged, setThemeChanged] = useState<string>(currentThemeName);

    const handleChangeTheme = (event: EventTarget): void => {
        if (event && currentThemeName == 'PureLightTheme') {
            setThemeChanged('DarkSpacesTheme');
            setThemeName('DarkSpacesTheme');
        } else {
            setThemeChanged('PureLightTheme');
            setThemeName('PureLightTheme');
        }
    };

    return (
        <>
            <Tooltip arrow title={t('Theme')}>
                <IconButtonWrapper
                    sx={{
                        transition: `${theme.transitions.create(['background'])}`,
                        color: theme.colors.primary.main,

                        '&:hover': {
                            background: alpha(theme.colors.primary.main, 0.2)
                        }
                    }}
                    color="primary"
                    ref={ref}
                    onClick={(e) => {
                        handleChangeTheme(e.target);
                    }}
                >
                    {themeChanged === 'PureLightTheme' ? (
                        <WbSunnyOutlinedIcon fontSize="small" />
                    ):<DarkModeOutlinedIcon fontSize="small"/>}
                </IconButtonWrapper>
            </Tooltip>
        </>
    );
}

export default HeaderTheme;

