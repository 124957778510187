import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';
import type {AppThunk} from 'src/store';
import type {Appointment, Meeting} from 'src/models/event';
import {
    deleteMeeting,
    getAppointmentsByDate,
    getAppointmentsByProviderDate,
    getMeetingsThreeMonth,
    saveMeeting
} from "../service/event-service";
import {getDateToString, toDay} from "../utils/utils";
import actions from "../content/provider/Calendar/Actions";

interface CalendarState {
    meeting?: Meeting;
    meetings: Meeting[];
    isDrawerOpen: boolean;
    appointments: Appointment[];
    selectedEventId: string | null;
    selectedRange: {
        start: number;
        end: number;
        startStr?: string
    } | null;
}

const initialState: CalendarState = {
    meetings: [],
    appointments: [],
    isDrawerOpen: false,
    selectedEventId: null,
    selectedRange: null,
};

const slice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        getMeetings(
            state: CalendarState,
            action: PayloadAction<any>
        ) {
            state.meetings = action.payload;
        },
        createEvent(state: CalendarState, action: PayloadAction<Meeting[]>) {
            state.meetings = [...state.meetings, ...action.payload];
        },
        selectEvent(
            state: CalendarState,
            action: PayloadAction<{ eventId?: string, appts: Appointment[] }>
        ) {
            const {eventId = null, appts} = action.payload;
            state.meeting = state.meetings.find(m => m.id == eventId);
            state.appointments = appts;
            state.selectedEventId = eventId;
            if(getDateToString(new Date(state?.meeting?.start)) >= toDay || appts?.length >0) {
                state.isDrawerOpen = true;
            }else {
                state.meeting = null;
                state.appointments = [];
                state.selectedEventId = null;
            }
        },
        updateEvent(state: CalendarState, action: PayloadAction<{ events: Meeting [] }>) {
            const {events} = action.payload;
            const currentMeetings = events.map((e) => e.id);
            const data: Meeting[] = state.meetings.filter(
                (m) => !currentMeetings.includes(m.id)
            );
            state.meetings = [...data, ...events];
        },
        deleteEvent(
            state: CalendarState,
            action: PayloadAction<{ events: Meeting[] }>
        ) {
            const {events} = action.payload;
            const currentMeetings = events.map((e) => e.id);
            const data: Meeting[] = state.meetings.filter(
                (m) => !currentMeetings.includes(m.id)
            );
            state.meetings = data;
        },
        selectRange(
            state: CalendarState,
            action: PayloadAction<{ start: number; end: number; startStr?: string }>
        ) {
            const {start, end, startStr} = action.payload;
            if (toDay > startStr)
                return;
            state.isDrawerOpen = true;
            state.selectedRange = {
                start,
                end,
                startStr
            };
        },
        openDrawerPanel(state: CalendarState) {
            state.isDrawerOpen = true;
        },
        closeDrawerPanel(state: CalendarState) {
            state.isDrawerOpen = false;
            state.selectedEventId = null;
            state.selectedRange = null;
        }
    }
});

export const reducer = slice.reducer;


export const getMeetings =
    (date: Date): AppThunk =>
        async (dispatch): Promise<void> => {
            try {
                const response = await getMeetingsThreeMonth(date.toISOString().split("T")[0])
                dispatch(slice.actions.getMeetings(response.data));
            } catch (error) {
                // console.error(error?.message)
            }
        };


export const createEvent =
    (data: any): AppThunk =>
        async (dispatch) => {
            const response = await saveMeeting(data);
            dispatch(slice.actions.createEvent(response.data));
        }
export const selectEvent =
    (eventId?: string, startDate?: string): AppThunk =>
        async (dispatch) => {
            let appts: Appointment[] = [];
            if (startDate) {
                const response = await getAppointmentsByProviderDate(startDate.split("T")[0]);
                appts = response?.data;
            }
            dispatch(slice.actions.selectEvent({eventId, appts}));
        };

export const updateEvent =
    (data: any): AppThunk =>
        async (dispatch) => {
            const response = await saveMeeting(data);
            dispatch(slice.actions.updateEvent({events: response.data}));
        };

export const deleteEvent =
    (eventId: string, frequency: String): AppThunk =>
        async (dispatch) => {
            const response = await deleteMeeting(eventId, frequency);
            dispatch(slice.actions.deleteEvent({events: response.data}));
        };

export const selectRange =
    (start: Date, end: Date, startStr?: string): AppThunk =>
        (dispatch) => {
            dispatch(
                slice.actions.selectRange({
                    start: start.getTime(),
                    end: end.getTime(),
                    startStr: startStr,
                })
            );
        };

export const openDrawerPanel = (): AppThunk => (dispatch) => {
    dispatch(slice.actions.openDrawerPanel());
};

export const closeDrawerPanel = (): AppThunk => (dispatch) => {
    dispatch(slice.actions.closeDrawerPanel());
};

export default slice;
