import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {AppThunk} from '../store';
import { User} from "../models/user";
import {getCurrentUser, login} from "../service/account-service";
import http from "../utils/http";

type AuthState = {
    isAuthenticated: boolean;
    user: User | null;
    token: string,
    isLoading: boolean | null

};

const initialState: AuthState = {
    isAuthenticated: false,
    user: null,
    token: null,
    isLoading: null
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        signIn: (state, {payload: {user}}: PayloadAction<{ user: User }>) => {
            localStorage.setItem('accessToken', user.token);
            http.defaults.headers.common.Authorization = `Bearer ${user.token}`;
            state.isAuthenticated = !!user;
            state.user = user;
            state.token = user.token;
        },
        updateUser: (state, {payload: user}: PayloadAction<User>) => {
            state.user = user;
            state.isAuthenticated = !!user;
            http.defaults.headers.common.Authorization = `Bearer ${state.token}`;
            state.isLoading = false;
        },
        setToken: (
            state,
            {payload: token}: PayloadAction<string>) => {
            state.isAuthenticated = !!token;
            state.token = token;
        },
        setIsLoading: (
            state: AuthState,
            action: PayloadAction<any>,
        ) => {
            const {isLoading} = action.payload;
            state.isLoading = isLoading;
        },
        loggedOut: (state) => {
            state.isAuthenticated = false
            state.user = null;
            state.token = null;
            state.isLoading = false;
        }
    }
});
export const {setToken} = slice.actions;

export const reducer = slice.reducer;

export const signIn = (user): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoading({isLoading: true}));
    try {
        dispatch(slice.actions.signIn({user}));
        dispatch(slice.actions.setIsLoading({isLoading: false}));
    } catch (error) {
        dispatch(slice.actions.setIsLoading({isLoading: false}));
    }
};

export const loggedOut = (stompClient): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setIsLoading({isLoading: true}));
    if(stompClient) {
        stompClient.connectHeaders = {};
        stompClient.forceDisconnect();
    }
    setSession(null);
    dispatch(slice.actions.loggedOut());
};

export const updateUser = (user: User | null): AppThunk => async (dispatch) => {
    try {
        dispatch(slice.actions.setIsLoading({isLoading: true}));
        const token = localStorage.getItem("accessToken");
        if (!user && token) {
            http.defaults.headers.common.Authorization = `Bearer ${token}`;
            user = await getCurrentUser();
            dispatch(slice.actions.updateUser(user));
            dispatch(slice.actions.setToken(token));
            dispatch(slice.actions.setIsLoading({isLoading: false}));
        } else if (user) {
            dispatch(slice.actions.updateUser(user));
            dispatch(slice.actions.setIsLoading({isLoading: false}));
        } else {
            dispatch(slice.actions.setIsLoading({isLoading: false}));
        }
    } catch (e) {
        setSession(null)
        dispatch(slice.actions.loggedOut());
    }
};

export const setSession = (accessToken: string | null, reload?: boolean): void => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        if (reload) window.location.reload();
    } else {
        localStorage.removeItem('accessToken');
        delete http.defaults.headers.common.Authorization;
    }
};