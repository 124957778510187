import { Consultation } from "src/models/consultation";
import { User } from "src/models/user";
import http from "src/utils/http";

export async function getAllConsultations(){
    return http.get("/consultation");
}

export async function saveConsultation(consultation: Consultation){
    return http.post<Consultation>("/consultation", consultation);
}

