import http from "../utils/http";
import {EventType} from "../models/eventType";
import {Appointment, Meeting} from "../models/event";


export async function getMeetingTypes() {
    const meetings = await http.get("/meetingType");
    return meetings.data;
}

export async function getSites(data) {
    const sites = await http.get("/site/search", {
        params: data,
    });
    return sites.data;
}


export async function getMeetingType() {
    const res = await http.get<EventType[]>('/meetingType');
    return res.data;
}

export async function getMeetingTypeByName(name){
    const res = await http.get<EventType>(`/meetingType/name/${name}`);
    return res.data;
}

export function getAllMeetings(){
    return  http.get('/meeting');
}

export async function getMeetingByOwnerAndToDay(date){
    return http.get<Meeting[]>(`/meeting/owner/${date}`);
}

export async function getMeetingsThreeMonth(date){
    return http.get<Meeting[]>(`/meeting/3/month?date=${date}`);
}


export async function saveMeeting(event){
    return  http.post<Meeting[]>("/meeting", event);
}

export async function deleteMeeting(id, frequency){
    return  http.delete(`/meeting/delete/${id}/${frequency}`);
}


export async function saveAppointment(data: Appointment) {
    return http.post('/appointment/update', data);
}

export async function getAppointmentsByMonth(date?: string, filter?: string) {
    return http.get(`/appointment/month?date=${date?date:""}&filter=${filter?filter:""}`);
}

export async function getProviderDashboard() {
    const rs = await http.get('/meeting/dashboard');
    return rs.data;
}

export async function getAppointmentsByDate(date: string) {
    return http.get(`/appointment/day/${date}`);
}


export async function getAppointmentsByProviderDate(date: string) {
    return http.get(`/appointment/provider/day/${date}`);
}
