import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';

import CallLeaveIcon from '@mui/icons-material/CallEndTwoTone';
import CallIcon from '@mui/icons-material/Call';
import MicNoneTwoToneIcon from '@mui/icons-material/MicNoneTwoTone';
import MicOffTwoToneIcon from '@mui/icons-material/MicOffTwoTone';
import { useTranslation } from 'react-i18next';
import {Fragment, useEffect, useState} from 'react';
import { VideocamOffTwoTone, VideocamTwoTone } from '@mui/icons-material';
import { useSelector } from '../../../../store';
import {getCurrentTime} from "../../../../utils/utils";

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
    padding: ${theme.spacing(1)};
    margin:1px;
    color: ${theme.palette.primary.contrastText};
    transform: translateY(0px);
    transition: ${theme.transitions.create([
      'color',
      'transform',
      'background'
    ])};
    
    .MuiSvgIcon-root {
        transform: scale(1);
        transition: ${theme.transitions.create(['transform'])};
    }

    &:hover {
        background: initial;
        transform: translateY(-2px);

        .MuiSvgIcon-root {
            transform: scale(1.2);
        }
    }
  `
);

const ThemeSettingsButton = styled(Box)(
  ({ theme }) => `
          position: relative;
          
          &::before {
              width: 30px;
              height: 30px;
              content: ' ';
              position: absolute;
              border-radius: 100px;
              left: 13px;
              top: 15px;
              background: ${theme.colors.success.main};
              animation: ripple 1s infinite;
              transition: ${theme.transitions.create(['all'])};
          }

          .MuiSvgIcon-root {
            animation: pulse 1s infinite;
            transition: ${theme.transitions.create(['all'])};
          }
  `
);

const TypographyPrimary = styled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.success.main};
`
);

const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: ${theme.spacing(1.8)};
    height: ${theme.spacing(1.8)};
    display: inline-block;
    margin-right: ${theme.spacing(0.8)};
    border: ${theme.colors.gradients.black2} solid 2px;
`
);
interface ControlButtonsProps {
  makeCall: () => void;
  answerCall: () => void;
  leaveCall: () => void;
  isReady: boolean;
    startTime: any;
  handleMuteUnmuteAudio: () => void;
  handleMuteUnmuteVideo: () => void;
  isLocalAudioMuted: boolean;
  isLocalVideoMuted: boolean;
  callType: 'audio' | 'video';
  isCaller: boolean;
  // calculateDuration: any;
}

export default function ControlButtons({
  makeCall,
  answerCall,
  leaveCall,
  isReady,
  startTime,
  // calculateDuration,
  handleMuteUnmuteAudio,
  handleMuteUnmuteVideo,
  isLocalAudioMuted,
  isLocalVideoMuted,
  callType,
  isCaller
}: ControlButtonsProps) {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { callState: state } = useSelector((state) => state.messenger);
  const [time, setTime] = useState('');

    const calculateDuration = () => {
        if (startTime) {
            const currentTime = Date.now();
            const elapsedTimeInSeconds = Math.floor((currentTime - startTime) / 1000);
            const minutes = Math.floor(elapsedTimeInSeconds / 60);
            const seconds = elapsedTimeInSeconds % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }

        return '0:00';
    };

    useEffect(() => {
      if (startTime) {
        const interval = setInterval(() => {
          setTime(getCurrentTime());
            calculateDuration()
        }, 1000);

        return () => clearInterval(interval);
      }
    }, [startTime]);

  return (
    <Fragment>
      <Box
        p={1}
        sx={{
          textAlign: 'start'
        }}
      >
          <TypographyPrimary variant="h4" lineHeight={2} gutterBottom noWrap>
               {startTime ? calculateDuration() : null}
          </TypographyPrimary>
      </Box>
      <Box display="flsetStartTimeex" flex={1} justifyContent="center" alignItems="center">
        <Box p={1} sx={{ textAlign: 'center' }}>
          {isReady && (
            <>
              <Tooltip
                arrow
                title={isLocalAudioMuted ? t('Mute') : t('Unmute')}
              >
                <IconButtonWrapper
                  onClick={handleMuteUnmuteAudio}
                  sx={{
                    mx: 2,
                    background: `${
                      isLocalAudioMuted
                        ? theme.colors.error.main
                        : theme.colors.primary.dark
                    }`,
                    '&:hover': {
                      background: `${theme.colors.gradients.black2}`
                    }
                  }}
                >
                  {isLocalAudioMuted ? (
                    <MicOffTwoToneIcon fontSize="medium" />
                  ) : (
                    <MicNoneTwoToneIcon fontSize="medium" />
                  )}
                </IconButtonWrapper>
              </Tooltip>
              {callType == 'video' && (
                <Tooltip
                  arrow
                  title={isLocalVideoMuted ? t('Open video') : t('Close video')}
                >
                  <IconButtonWrapper
                    onClick={handleMuteUnmuteVideo}
                    sx={{
                      mx: 2,
                      background: `${
                        isLocalVideoMuted
                          ? theme.colors.error.main
                          : theme.colors.primary.dark
                      }`,
                      '&:hover': {
                        background: `${theme.colors.gradients.black2}`
                      }
                    }}
                  >
                    {isLocalVideoMuted ? (
                      <VideocamOffTwoTone fontSize="medium" />
                    ) : (
                      <VideocamTwoTone fontSize="medium" />
                    )}
                  </IconButtonWrapper>
                </Tooltip>
              )}

              {(state === 'outgoing' || state === 'answered') && (
                <Tooltip arrow title={'Leave this call'}>
                  <IconButtonWrapper
                    onClick={leaveCall}
                    sx={{
                      mx: 2,
                      background: `${theme.colors.error.main}`,
                      '&:hover': { background: `${theme.colors.primary.dark}` }
                    }}
                  >
                    <CallLeaveIcon />
                  </IconButtonWrapper>
                </Tooltip>
              )}
              {state === 'new' && (
                <Fragment>
                  <Tooltip arrow title={t('Make Call')}>
                    <IconButtonWrapper
                      onClick={makeCall}
                      sx={{
                        mx: 2,
                        background: `${
                          isCaller
                            ? theme.colors.error.main
                            : theme.colors.primary.main
                        }`,
                        '&:hover': {
                          background: `${
                            isCaller
                              ? theme.colors.error.main
                              : theme.colors.primary.dark
                          }`
                        }
                      }}
                    >
                      <CallIcon />
                    </IconButtonWrapper>
                  </Tooltip>
                </Fragment>
              )}

              {state === 'incoming' && (
                <Tooltip arrow title={t('Please Answer this call')}>
                  <IconButtonWrapper
                    onClick={answerCall}
                    sx={{
                      mx: 2,
                      background: `${theme.colors.success.main}`,
                      '&:hover': { background: `${theme.colors.success.light}` }
                    }}
                  >
                    <ThemeSettingsButton>
                      <CallIcon />
                    </ThemeSettingsButton>
                  </IconButtonWrapper>
                </Tooltip>
              )}
            </>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <DotLegend
          style={{
            animation: `pulse 1s infinite`,
            background: `${
              state === 'answered'
                ? theme.colors.warning.main
                : state === 'incoming'
                ? theme.colors.success.main
                : state === 'outgoing'
                ? theme.colors.primary.main
                : theme.colors.info.main
            }`
          }}
        />
        <Typography
          variant="h6"
          sx={{
            color: `${theme.colors.info.dark}`,
            mr: 2
          }}
        >
          {state === 'answered'
            ? t('Call in progress')
            : state === 'outgoing'
            ? t('In call')
            : state === 'incoming'
            ? t('Please Answer this call')
            : t('no call')}
        </Typography>
      </Box>
    </Fragment>
  );
}
