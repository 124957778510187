import {
  Box,
  IconButton,
  Badge,
  Tooltip,
  TooltipProps,
  alpha,
  tooltipClasses,
  styled,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import EventTwoToneIcon from '@mui/icons-material/EventTwoTone';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import LightbulbTwoTone from '@mui/icons-material/LightbulbTwoTone';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import {useState} from "react";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    boxShadow: theme.shadows[24],
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(12)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function SidebarFooter() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [itemActive, setItemActive ] = useState(null);

  return (
    <Box
      sx={{
        height: 60
      }}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <LightTooltip placement="top" arrow title={t('Home')}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[`${itemActive == 'home'? 50: 10}`]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,
            mx:1,


            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          to="/teleconsultation/provider/dashboard"
          component={RouterLink}
          onClick={()=>setItemActive('home')}
        >
          <HomeOutlined fontSize="small" />
        </IconButton>
      </LightTooltip>
      <LightTooltip placement="top" arrow title={t('Calendar')}>
        <IconButton
          sx={{
            background: `${theme.colors.alpha.trueWhite[`${itemActive == 'calendar'? 50: 10}`]}`,
            color: `${theme.colors.alpha.trueWhite[70]}`,
            transition: `${theme.transitions.create(['all'])}`,

            '&:hover': {
              background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
              color: `${theme.colors.alpha.trueWhite[100]}`
            }
          }}
          to="/teleconsultation/provider/agenda"
          onClick={()=>setItemActive('calendar')}
          component={RouterLink}
        >
          <EventTwoToneIcon fontSize="small" />
        </IconButton>
      </LightTooltip>
      <LightTooltip placement="top" arrow title={t('Messenger')}>
          <IconButton
            to="/teleconsultation/provider/messenger"
            component={RouterLink}
            sx={{
              background: `${theme.colors.alpha.trueWhite[`${itemActive == 'messenger'? 50: 10}`]}`,
              color: `${theme.colors.alpha.trueWhite[70]}`,
              transition: `${theme.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`
              },
              mx: 1
            }}
            onClick={()=>setItemActive('messenger')}
          >
            <SmsTwoToneIcon fontSize="small" />
          </IconButton>
        {/* </Badge> */}
      </LightTooltip>
      <LightTooltip placement="top" arrow title={t('Counselings Dashboard')}>
          <IconButton
            to="/teleconsultation/provider/counselings-dashboard"
            component={RouterLink}
            sx={{
              background: `${theme.colors.alpha.trueWhite[`${itemActive == 'dashboard'? 50: 10}`]}`,
              color: `${theme.colors.alpha.trueWhite[70]}`,
              transition: `${theme.transitions.create(['all'])}`,

              '&:hover': {
                background: `${alpha(theme.colors.alpha.trueWhite[100], 0.2)}`,
                color: `${theme.colors.alpha.trueWhite[100]}`
              },
            }}
            onClick={()=>setItemActive('dashboard')}
          >
            <LightbulbTwoTone fontSize="small" />
          </IconButton>
      </LightTooltip>
    </Box>
  );
}

export default SidebarFooter;
