import { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react';

import {
  IconButton,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Button,
  Divider,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
  ListItemText,
  alpha,
  Popover,
  Tooltip,
  Avatar,
  styled,
  useTheme
} from '@mui/material';
import Text from 'src/components/Text';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import Scrollbar from 'src/components/Scrollbar';
import ForumTwoToneIcon from '@mui/icons-material/ForumTwoTone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'src/store';
import { getUsers } from 'src/slices/users';
import { Role, User } from 'src/models/user';
import { downloadFile } from 'src/service/file-service';
import { useNavigate } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { resetStateMessenger, setUserSelected } from 'src/slices/messenger';
import { readMessageNotification } from 'src/slices/notifications';



const DotLegend = styled('span')(
  ({ theme }) => `
    border-radius: 22px;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

const OutlinedInputWrapper = styled(OutlinedInput)(
  ({ theme }) => `
    background-color: ${theme.colors.alpha.white[100]};

    .MuiOutlinedInput-notchedOutline {
        border: 0;
    }
`
);

const ListWrapper = styled(List)(
  () => `
    .MuiListItem-root:last-of-type + .MuiDivider-root {
        display: none;
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function Chat() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const { users } = useSelector(state => state.users);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [filteredUsers, setFilteredUsers] = useState<User[]>(null)
  const [criteria, setCriteria] = useState("");
  const [query, setQuery] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleUserSelected = (us) => {
    dispatch(resetStateMessenger())
    dispatch(setUserSelected(us))
    dispatch(readMessageNotification(us?.id))
    // if (isWebSocketConnect) {
    //   stompClient.subscribe(`/topic/messages/${user?.id}`)
    // }
    navigate(`/teleconsultation/${user.profile.role == Role.PRESTATAIRE?'provider':'configuration'}/messenger`);
    setOpen(false)
  }

  const handleGoTchat = () => {
    dispatch(setUserSelected(null))
    navigate(`/teleconsultation/${user.profile.role == Role.PRESTATAIRE?'provider':'configuration'}/messenger`);
    setOpen(false)
  }

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    const criteria = event.target.value;
    setQuery(criteria);
    if (criteria.length > 2 || criteria.length == 0)
      setCriteria(criteria);
  };

  useEffect(() => {
    dispatch(getUsers({ filter: query }));
  }, [dispatch, query]);

  useEffect(() => {
    if (users?.length > 0) {
      const items = users?.filter(user => (user?.profile?.role != Role.ADMIN) && (user?.profile?.role != Role.SUPER) && user?.online);
      setFilteredUsers(items);
    } else {
      setFilteredUsers([]);
    }
  }, [users]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title={t('Messenger')}>
        <IconButtonWrapper
          color="warning"
          ref={ref}
          onClick={handleOpen}
          sx={{
            background: alpha(theme.colors.warning.main, 0.1),
            transition: `${theme.transitions.create(['background'])}`,
            color: theme.colors.warning.main,

            '&:hover': {
              background: alpha(theme.colors.warning.main, 0.2)
            }
          }}
        >
          <ForumTwoToneIcon fontSize="small" />
        </IconButtonWrapper>
      </Tooltip>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box minWidth={360} maxWidth={360}>
          <Divider />
          <Box>
            <FormControl variant="outlined" fullWidth>
              <OutlinedInputWrapper
                type="text"
                placeholder={t('Search online people...')}
                onChange={handleQueryChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <Divider />
          <Box
            sx={{
              height: 200
            }}
          >
            <Scrollbar>
              <ListWrapper disablePadding>
                {filteredUsers?.map((user, i) => (
                  <Fragment key={i}>
                    <ListItem
                      sx={{
                        py: 1.5,
                        '&:hover': {
                          background: `${theme.colors.alpha.black[5]}`
                        }
                      }}
                      secondaryAction={
                        <Button
                          size="small"
                          variant="text"
                          color="secondary"
                          sx={{
                            alignSelf: 'center',
                            padding: `${theme.spacing(0.5, 1.5)}`,
                            backgroundColor: `${theme.colors.secondary.lighter}`,
                            textTransform: 'uppercase',
                            fontSize: `${theme.typography.pxToRem(11)}`,
                            '&:hover': {
                              backgroundColor: `${theme.colors.secondary.main}`,
                              color: `${theme.palette.getContrastText(
                                theme.colors.secondary.main
                              )}`
                            }
                          }}
                          onClick={() => handleUserSelected(user)}
                        >
                          {t('Chat')}
                        </Button>
                      }
                    >
                      <ListItemAvatar
                        sx={{
                          minWidth: 38,
                          mr: 1
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 38,
                            height: 38
                          }}
                          alt={user.person.fullname}
                          src={downloadFile(user.person.picture)}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        sx={{
                          flexGrow: 0,
                          maxWidth: '50%',
                          flexBasis: '50%'
                        }}
                        disableTypography
                        primary={
                          <Typography
                            sx={{
                              pb: 0.6
                            }}
                            color="text.primary"
                            variant="h5"
                          >
                            {user.person.firstname}{' '}{user.person.lastname}
                          </Typography>
                        }
                        secondary={
                          <Box display="flex" alignItems="flex-start">
                            <DotLegend
                              style={{
                                background: `${theme.colors.success.main}`
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: `${theme.typography.pxToRem(11)}`,
                                lineHeight: 1
                              }}
                              variant="body1"
                            >
                              <Text color="success">{t('Online')}</Text>
                            </Typography>
                          </Box>
                        }
                      />
                    </ListItem>
                    <Divider />
                  </Fragment>
                ))}
              </ListWrapper>
            </Scrollbar>
          </Box>
          <Divider />
          <Box
            sx={{
              background: `${theme.colors.alpha.black[5]}`,
              textAlign: 'center'
            }}
            p={2}
          >
            <Button
              size="small"
              color="primary"
              variant="contained"
              endIcon={<ArrowForwardTwoToneIcon />}
              onClick={handleGoTchat}
            >
              {t('View all')}
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default Chat;
