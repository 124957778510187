import {FC} from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import {Role, Status} from "../../models/user";
import {AuthenticatedProps} from "./index";
import {Navigate} from 'react-router-dom';
import {RootState, useSelector} from "../../store";


const Recipient: FC<AuthenticatedProps> = (props: { children: any }) => {
    const {children} = props;
    const {user, isLoading, isAuthenticated} = useSelector((state: RootState) => state.auth);

    if (!isAuthenticated) {
        return <Navigate to="/account/login"/>;
    } else if (!(Role.BENEFICIAIRE == user?.profile?.role)) {
        return <Navigate to="/status/unauthorized"/>;
    }
    return (<>{isLoading === false && children}</>);
};

Recipient.propTypes = {
    children: PropTypes.node
};

export default Recipient;
