import {useContext, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from "../store";
import {loggedOut, signIn, updateUser} from "../slices/auth";
import {User} from "../models/user";

const useAuth = () => {
    const {user, isLoading, isAuthenticated} = useSelector((state: RootState) => state.auth);

    const dispatch: AppDispatch = useDispatch();
    const logout = async (stompClient) => {
        dispatch(loggedOut(stompClient));
    };

    const onUpdateUser = (user: User) => {
        dispatch(updateUser(user));
    }
    const login = (user) => {
        dispatch(signIn(user));
    };


    return useMemo(
        () => ({
            user,
            signIn: login,
            isAuthenticated,
            logout,
            onUpdateUser,
            isLoading
        }),
        [user],
    );
}

export default useAuth;