import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Link,
    Typography,
    Container,
    styled,
    Card, Alert
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';
import { useTranslation } from 'react-i18next';
import React from "react";


const MainContent = styled(Box)(
    () => `
    height: 85%;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);


function LoginBasic() {
    const { method } = useAuth() as any;
    const { t }: { t: any } = useTranslation();

    return (
        <>
            <MainContent>
                <Container maxWidth="md">
                    <Card
                        sx={{
                            p: 4,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h2"
                            >
                                {t("Login")}
                            </Typography>
                            <Typography
                                variant="h4"
                                color="secondary"
                                fontWeight="normal"
                                sx={{
                                    mb: 5
                                }}
                            >
                                {t('Fill in the fields below to sign into your account.')}
                            </Typography>

                        </Box>
                         <JWTLogin />
                    </Card>
                </Container>
            </MainContent>

            <Box
                alignItems="center"
                display={{ xs: 'block', md: 'flex' }}
                mb={4}
                justifyContent="center"
                mt={1}
            >
                {(<Alert severity="info">
                    <Link
                        color={"navy"}
                        href="https://expo.dev/artifacts/eas/5KuCsLws4DL1Lu2Zwfv3jV.apk"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Télécharger l'apk ici. version: 0.3.1, Size fichier : 34.1 Mb, Version android:^8.0, 91.85 Mb Mb pour l'installation
                </Link>
                </Alert>)
                }
            </Box>

            <Box textAlign="center">
                <Typography
                    component="span"
                    variant="subtitle1"
                    color="text.primary"
                    fontWeight="bold"
                >
                    {t("Don’t have an account yet? ")}
                </Typography>{' '}
                <Link component={RouterLink} to="/account/register">
                    <b>
                        {t("Register here")}
                    </b>
                </Link>
            </Box>
            <Box textAlign="center" sx={{ mb: 2 }}>
                <Typography
                    component="span"
                    variant="body1"
                    color="text.primary"
                    fontSize={10}
                >
                    {t('Powered by SEROvie')}
                </Typography>{' '}
            </Box>
        </>
    );
}

export default LoginBasic;
