const enJSON = {
    'Welcome to SEROvie! Setup your account.': 'Welcome to SEROvie! Setup your account.',
    'Fill in the fields below to sign up for an account.': 'Fill in the fields below to sign up for an account.',
    'Already have an account?': 'Already have an account?',
    'Sing in': 'Sing in',
    'The givename field is required': 'The givename field is required',
    'The lastname field is required': 'The lastname field is required',
    'Email already exist': 'Email already exist',
    'The email provided should be a valid email address': 'The email provided should be a valid email address',
    'The email field is required': 'The email field is required',
    'The name field is required': 'The name field is required',
    'Password must have minimum 8 characters': 'Password must have minimum 8 characters',
    'Password must not exceed 50 characters': 'Password must not exceed 50 characters',
    'Password format is incorrect': 'Password format is incorrect',
    'Passwords are not identical': 'Passwords are not identical',
    'Password is required': 'Password is required',
    'Confirm Password is required': 'Confirm Password field is required',
    'Username Required': 'Username field Required',
    'Username required more than 3 characters': 'Username required more than 3 characters',
    'Username already exist': 'Username already exist',
    'Connect my account with': 'Connect my account with',
    'Sign up': 'Sign up',
    'Given Name': 'Given Name',
    'Last Name': 'Last Name',
    'Email address': 'Email adress',
    'Confirm Password': 'Confirm Password',
    'User profile': 'User profile',
    'User status': 'User status',
    'Manage Users': 'Manage Users',
    'User List': 'User List',
    'Users Management Message Header': 'Users Management system section',
    'Create user': 'Create user',
    'Administrator': 'Administrator',
    'Admin System': 'Admin System',
    'Adminnistration': 'Administration',
    'Manage Counseling': 'Manage Counseling',
    'Counseling': 'Counseling',
    'Counseling List': 'Counseling List',
    'Login': "Login",
    'Username': "Username",
    'Password': "Password",
    "Forgotten Password ?": "Forgotten Password ?",
    "Sign in": "Sign in",
    "Don’t have an account yet? ": "Don’t have an account yet? ",
    "Register here": "Register here",
    "Fill in the fields below to sign into your account.": "Fill in the fields below to sign into your account.",
    "Reset password": "Reset password",
    "Delete selected counseling": "Delete selected counseling",
    "This action is not reversible and will delete all selected counseling(s).": "This action is not reversible and will delete all selected counseling(s).",
    "Are you sure to want to continue ?": "Are you sure to want to continue ?",
    "Delete counseling": "Delete counseling",
    "Cancel": "Cancel",
    "Delete": "Delete",
    'Postpone publication': 'Postpone publication',
    'Status': 'Status',
    'Name': 'Name',
    'Publication date': 'Publication date',
    'Preview': 'Preview',
    'Save draft': 'Save draft',
    'Publish': 'Publish',
    'Add image': 'Add image',
    'Drop the files to start uploading': 'Drop the files to start uploading',
    'Text title here...': 'Text title here...',
    'Your counseling text body here': 'Your counseling text body here',
    'Create new topic': 'Create new topic',
    'Fill in the fields below to create a new topic': 'Fill in the fields below to create a new topic',
    'Counselings Management': 'Counselings Management',
    'Here we manage all about counselings': 'Here we manage all about counselings',
    'Edit counseling': 'Edit counseling',
    'We couldn\'t find any counseling matching your search criteria': 'We couldn\'t find any counseling matching your search criteria',
    'f': 'Female',
    'm': 'Male',
    'i': 'unknown',
    'Phone': 'Phone',
    'Select the profile for user': 'Select the profile for user',
    'Add new user': 'Add new user',

    'Provider': 'Provider',
    'Home': 'Home',
    'Agenda': 'Agenda',
    'Messenger': 'Messenger',
    'messenger': 'Messenger',
    'Manage Officier': 'Manage Officier',
    'Form': 'Form',
    'List': 'List',

    'Meetings and Appointement': 'Meetings and Appointement',
    'Check out the latest meetings and meetings in your calendar': 'Check out the latest meetings and meetings in your calendar',
    'The event has been deleted': 'The event has been deleted',
    'The end date should be after start date': 'The end date should be after start date',
    'The calendar has been successfully updated!': 'The calendar has been successfully updated!',
    'Create new calendar event': 'Create new calendar event',
    'Edit calendar event': 'Edit calendar event',
    'Select your event type': 'Select your event type',
    'Select prefered work day': 'Select prefered work day',
    'All days': 'All days',
    'Event end date': 'Event end date',
    'Calendar': 'Calendar',

    'Welcome Dr': 'Welcome Dr',
    'Since your last login there were': 'Since your last login there were',
    'new patients appointments': 'new patients appointments',
    'and': 'and',
    'new messages': 'new messages',
    'Decline': 'Decline',
    'Confirm': 'Confirm',
    'You confirmed this Appointment successfuly!': 'You confirmed this Appointment successfuly!',
    'You declined this Appointment': 'You declined this Appointment',
    'You canceled this appointment.': 'You canceled this appointment.',
    'Appointment Confirmation': 'Appointment Confirmation',
    'Once you click on the YES button, this person will be added to your appointment list for consultation later. Do you really want to confirm ?': 'Once you click on the YES button, this person will be added to your appointment list for consultation later. Do you really want to confirm ?',
    'Appointment declination': 'Appointment declination',
    'Once click on the YES button, this person will be removed from your list of confirmation. This action is irreversible. Do you really want to decline ?': 'Once click on the YES button, this person will be removed from your list of confirmation. This action is irreversible. Do you really want to decline ?',
    'Declined': 'Declined',
    'Pending': 'Pending',
    'Confirmed': 'Confirmed',
    'Expired': 'Expired',
    'All': 'All',
    'Type': 'Type',
    'Motivation': 'Motivation',
    'Request an appointment': 'Request an appointment',
    'Yes': 'Yes',
    'No': 'No',
    'Filters': 'Filters',
    'Search appointement...': 'Search appointments',
    'You have no consultation(s) yet!': 'You have no consultation(s) yet!',
    'Your consulations': 'Your consulations',

    'You are about to start a consultation for this patient.': 'You are about to start a consultation for this patient.',
    'When you click on the start button, you will be redirected to the consultation mode indicated.': 'When you click on the start button, you will be redirected to the consultation mode indicated.',
    'Make sure you have isantePLUS open in your browser before starting your consultation.': 'Make sure you have isantePLUS open in your browser before starting your consultation.',
    'Start of consultation': 'Start of consultation',
    'Start': 'Start',
    'Consultation': 'Consultation',
    'Reason for consultation': 'Reason for consultation',
    'Search online people...': 'Search online people...',
    'View all': 'View all',

    'Update Speciality': 'Update Speciality',
    'New Speciality': 'New Speciality',
    'Fill in the fields below to': 'Fill in the fields below to',
    'update': 'update',
    'create': 'create',
    'a speciality': 'a speciality',
    'The speciality name is required': 'The speciality name is required',
    'The speciality description is required': 'The speciality description is required',
    'Speciality': 'Speciality',
    'Description': 'Description',
    'Save': 'Enregistrer',
    'The speciality(ies) has been deleted successfully': 'The site\'s status are changed successfully',
    'The speciality(ies) deletion failed': 'The speciality(ies) deletion failed',
    'Delete speciality(ies)': 'Delete speciality(ies)',
    'Manage Specialities': 'Manage Specialities',
    'Speciality Management': 'Speciality Management',
    'Add speciality': 'Add speciality',
    'Search by name or description...': 'Search by name or description...',
    'We couldn\'t find any speciality matching your search criteria': 'We couldn\'t find any speciality matching your search criteria',
    'Creator': 'Creator',
    'Created date': 'Created date',
    'Editor': 'Editor',
    'Edited date': 'Edited date',
    'Action': 'Action',
    'View': 'View',
    'The speciality was added successfully': 'The speciality was added successfully',
    'Speciality already exist': 'Speciality already exist',
    'Fill the fields below to provide a new personal password.': 'Fill the fields below to provide a new personal password.',
    'counseling(s)': 'counseling(s)',
    'All counseling': 'All counselings',
    'Published counseling': 'Published counseling',
    'Pending counseling': 'Pending counseling',
    'published': 'Published',
    'draft': 'Draft',
    'In draft': 'In draft',
    'Counseling published for this month': 'Counseling published for this month',
    "Check how many counseling are publiseh per day for the current month": "Check how many counseling are publiseh per day for the current month",
    'Create counseling': 'Create counseling',
    'delivered': 'delivered',
    'viewed': 'viewed',
    'Today targets reached': 'Today targets reached',
    'These are your performance indicators on delivered counseling view': 'These are your performance indicators on delivered counseling view',
    'Delivered view': 'Delivered view',
    'Viewed view': 'Viewed view',
    'Go to counselings home': 'Go to counselings home',
    'Add new counseling': 'Add new counseling',
    'Password format not match': 'Password incorrect format, requires at least one capital letter or one number',
    'Pasword is not identic': 'Passwords are not identical',
    'Select profile': 'Select your profile',

    'Search by name, email or username...': 'Search by name, email or username...',
    'We couldn\'t find any users matching your search criteria': 'We couldn\'t find any users matching your search criteria',
    'Fullname': 'Fullname',
    'Language': 'Language',
    'Actions': 'Actions',
    'All users': 'All users',
    'Email': 'Email',
    'pending': 'Pending',
    'active': 'Active',
    'inactive': 'Inactive',
    'locked': 'Locked',
    'System administrator': 'System administrator',
    'Care recipient': 'Care recipient',
    'Care provider': 'Care provider',
    'Rows per page': 'Rows per page',
    'Select user profile': 'Select user profile',
    'Select user locale': 'Select user locale',
    'Update user': 'Update user',
    'Counselings Dashboard': 'Counselings Dashboard',

    'Address': 'Address',
    'Location': 'Location',
    'Search by name, address or location...': 'Search by name, address or location...',
    'Manage Site': 'Manage Site',
    'Site Management Message Header': 'Here we do all about site management.',
    'Create site': 'Create Site',
    'Close selected site': 'Close selected site',
    'We couldn\'t find any site matching your search criteria': 'We couldn\'t find any site matching your search criteria',
    'Edit site': 'Edit site',
    'Status user': 'Update status',
    'Start call': 'Start call',
    'Call type': 'Call type',
    'Video': 'Video',
    'Call Video': 'Call Video',
    'Call Audio': 'Call Audio',
    'User profil information': 'User profil information',
    'Personal Information': 'Contact details',
    'Shared Files': 'Shared Files',
    'Chat': 'Chat',
    'Meetings': 'Meetings',
    'You have no meetings!': 'You have no meetings!',
    'Files': 'Files',
    'Online': 'Online',
    'To day': 'To day',
    'Write your message here...': 'Write your message here...',
    'Choose an emoji': 'Choose an emoji',
    'Attach a file': 'Attach a file',
    'Send voice': 'Send voice',
    'Select site location': 'Select site location',
    'Search...': 'Search...',
    'Manage Sites': 'Manage Sites',

    'Switch language': 'Switch language',
    'Notifications': 'Notifications',
    'Theme': 'Theme',
    'Work time': 'Work time',
    'Event start date': 'Event start date',
    'Add meeting': 'Confirm availability',
    'Save modifications': 'Update available',
    'Select frequency': 'Select frequency',
    'Mon': 'Mon',
    'Tue': 'Tue',
    'Wed': 'Wed',
    'Thu': 'Thu',
    'Fri': 'Fri',
    'Sat': 'Sat',
    'Sun': 'Sun',
    'Repeat one': 'Repeat one',
    'Only this event': 'Only this event',
    'All this month': 'All this monthTout',
    'All similar events': 'All similar events',
    'Previous Day': 'Previous Day',
    'Today': 'Today',
    'Next Day': 'Next Day',
    'Month': 'Month',
    'Week': 'Week',
    'Day': 'Day',
    'Customize your consultation duration': 'Customize your consultation duration',

    'Select speciality': 'Select speciality',
    'Select your site location': 'Select your site location',
    'Gender': 'Gender',
    'Update profile': 'Update profile',
    'Change profil info': 'Change profile info',
    'Fill in the fields below to change information about your profile': 'Fill in the fields below to change information about your profile',

    'Go back': 'Go to Home',
    'Profile for': 'Profile for',
    'This is your profile page. Find all the informations about account and feel free to modify them': 'This is your profile page. Find all the informations about account and feel free to modify them',
    'Edit your profile': 'Edit your profile',
    'Information': 'Information',
    'Security': 'Security',
    'Personal Details': 'Personal Details',
    'Manage informations related to your personal details': 'Manage informations related to your personal details',
    'Account Settings': 'Account Settings',
    'Manage details related to your account': 'Manage details related to your account',
    'Manage details related to your professional profile': 'Manage details related to your professional profile',
    'Account type': 'Account type',
    'Account status': 'Account status',
    'Professional information': 'Professional information',
    'Manage details related to your associated email addresses': 'Manage details related to your associated email addresses',
    'Health center': 'Health center',
    'Spécialisation': 'Spécialisation',
    'all': 'All',

    'Stay connected': 'Stay connected',
    'You have': 'You have',
    'Appointments': 'Appointments',
    'appointment': 'Appointments',
    'cette demande de rendez-vous a été cancel': "This appointment request was cancelled",

    'Counseling reports dashboard': 'Counseling reports dashboard',
    'Meetings and Appointments': 'Meetings and Appointments',
    'Current Date': 'Date is ',

    'End consultation': 'End consultation',
    'Add patient to a category': 'Add patient to a category',
    'Please don\'t forget to add the patient category.': 'Please don\'t forget to add the patient category.',
    'When you click on the end button, you will close the consultation.': 'When you click on the end button, you will close the consultation.',
    'You are about to end the consultation with the patient.': 'You are about to end the consultation with the patient.',
    'End of consultation': 'End of consultation',
    'Stop consultation': 'Stop consultation',
    'Stop Conversation': 'Stop Conversation',
    'Reset Conversation': 'Reset Conversation',
    'Start Conversation': 'Start Conversation',
    'Please give the reason for your refusal': 'Please give the reason for your refusal',
    'The user profil was changed successfully': 'The user profil was changed successfully',
    'reconnecting': 'Reconnecting...',
    'profile': 'Profile',
    'No publications today': 'No publications today',

    'Publish for a specific category': 'Publish for a specific category',
    'Postpone counseling publication': 'Postpone counseling publication',
    'Go to counselings list': 'Go to counselings list',
    'Refusal Reason': 'Refusal Reason',
    'Refusal Reason Error': 'Please give the reason before declining this appointment.',
    'profileUpdatedbeneficiaire': 'Updated profile to  Care recipient',
    'profileUpdatedadmin': 'Updated profile to System administrator',
    'profileUpdatedofficier': 'Updated profile to Manage Officierr',
    'profileUpdatedprestataire': 'Updated profile to Manage Officier',
    'of': 'of',
    'users': 'users(s)',
    'updatedinactive': 'Disable account',
    'updatedactive': 'Enable account',
    'cant call offline': 'You can\'t call now, you\'re offline right now',
    'offline trying to reconnect': 'You\'re offline, trying to reconnect',
    'incommingCallVideo': 'It is a appoitment for video call',
    'incommingCallAudio': 'It is a appoitment for audio call',

};

export default enJSON;
