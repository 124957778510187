import React, {FC, Fragment, ReactNode, useEffect, useRef, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import {RootState, useDispatch, useSelector} from 'src/store';
import {useNavigatorOnLine} from "../../hooks/useNavigatorOnLine";
import {StompHeaders, useStompClient, useSubscription} from "react-stomp-hooks";

export interface AuthenticatedProps {
    children: ReactNode;
}

const ProviderUser: FC<AuthenticatedProps> = (props: { children: any }) => {
        const {children} = props;
        const {onUpdateUser} = useAuth();
        const {
            isLoading,
            token,
            user
        } = useSelector((state: RootState) => state.auth);
        const dispatch = useDispatch();
        const location = useLocation();
        const [requestedLocation, setRequestedLocation] = useState<string | null>(
            null
        );
        const isOnline = useNavigatorOnLine();
        const stompClient = useStompClient();


        useEffect(() => {
                if (isLoading === false && token && stompClient?.connected ) {
                    stompClient.connectHeaders = {
                        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                    }
                    stompClient.publish({
                        destination: "/app/v2/login",
                        body: token
                    })
                }
            }, [isLoading, token, stompClient]
        );


        useEffect(() => {
            if (isLoading == null) {
                onUpdateUser(null);
            }
        }, [isLoading])


        const launch = () => {
            if (requestedLocation && location.pathname !== requestedLocation) {
                setRequestedLocation(null);
                return <Navigate to={requestedLocation}/>;
            } else {
                return <>{children}</>;
            }
        }

        return <Fragment>{isLoading === false && launch()}</Fragment>;
    }
;

ProviderUser.propTypes = {
    children: PropTypes.node
};

export default ProviderUser;
