import {mock} from 'src/utils/axios';
import wait from 'src/utils/wait';
import {sign, decode, JWT_SECRET, JWT_EXPIRES_IN} from 'src/utils/jwt';
import randomId from 'src/utils/randomId';
//
const users = [
    {
        id: '1',
        person:{
            id: '1',
            picture: '/static/images/avatars/1.jpg',
            firstname: 'Rafael',
            lastname: 'Kunde',
            fullname: 'Rafael Kunde',
            phone: "509 4265-6655",
            email: 'Monte.Auer31@yahoo.com',
        },
        username: 'Delphia22',
        password: '546tgvbgfdserytuh',
        locale: 'fr',
        status: 'ENABLE',
        profile: {
            id: 1,
            role: 'super',
            name: 'Super Administrateur',
            page: '/extends/admin',
        },
        // scope: {
        //     id: 1,
        //     name: 'Ouest',
        //     nature: 'Departement',
        // },
        token: 'hgghjghfjhfhjfhj'
    },
];
//
mock.onPost('/api/account/login').reply(async (config) => {
    await wait(1000);

    try {
        const {email, password} = JSON.parse(config.data);

        const user = users.find((_user) => _user.person.email === email);

        if (!user || user.password !== password) {
            return [
                400,
                {message: 'Verify that your email and password are correct'}
            ];
        }

        const accessToken = sign({userId: user.id}, JWT_SECRET, {
            expiresIn: JWT_EXPIRES_IN
        });

        return [
            200,
            {
                id: user.id,
                person: {
                    id: user.person.id,
                    picture: user.person.picture,
                    firstname: user.person.firstname,
                    lastname: user.person.lastname,
                    fullname: user.person.fullname,
                    phone: user.person.phone,
                    email: user.person.email,
                },
                username: user.username,
                password: user.password,
                locale: user.locale,
                status: user.status,
                profile: user.profile,
                // site: user?.site,
                token: user.token
            }
        ];
    } catch (err) {
        console.error('Error: ', err);
        return [500, {message: 'Encountered a server error'}];
    }
});
//
mock.onPost('/api/account/register').reply(async (config) => {
    await wait(1000);

    try {
        const {email, name, password} = JSON.parse(config.data);

        let user = users.find((_user) => _user.person.email === email);

        if (user) {
            return [400, {message: 'This user already exists'}];
        }

        user = {
            id: randomId(),
            person:{
                id: user.person.id,
                picture: user.person.picture,
                firstname: user.person.firstname,
                lastname: user.person.lastname,
                fullname: user.person.fullname,
                phone: user.person.phone,
                email: user.person.email,
            },
            username: user.username,
            password: user.password,
            locale: user.locale,
            status: user.status,
            profile: user.profile,
            // site: user.site,
            token: user.token

            // id: randomId(),
            // avatar: null,
            // email,
            // username: null,
            // password,
            // profile: null,
        };

        users.push(user);

        const accessToken = sign({userId: user.id}, JWT_SECRET, {
            expiresIn: JWT_EXPIRES_IN
        });

        return [
            200, {
                user: {
                    person:{
                        id: user.person.id,
                        picture: user.person.picture,
                        firstname: user.person.firstname,
                        lastname: user.person.lastname,
                        fullname: user.person.fullname,
                        phone: user.person.phone,
                        email: user.person.email,
                    },
                    username: user.username,
                    password: user.password,
                    locale: user.locale,
                    status: user.status,
                    profile: user.profile,
                    // site: user.site,
                    token: user.token

                    // id: randomId(),
                    // avatar: null,
                    // email,
                    // username: null,
                    // password,
                    // profile: null,
                }
            }
        ];
    } catch (err) {
        console.error('Error: ', err);
        return [500, {message: 'Encountered a server error'}];
    }
});

mock.onGet('/api/account/personal').reply((config) => {
    try {
        const {Authorization} = config.headers;

        if (!Authorization) {
            return [401, {message: 'Auth token is missing'}];
        }

        const accessToken = (Authorization as string).split(' ')[1];
        const {userId} = decode(accessToken) as any;
        const user = users.find((_user) => _user.id === userId);

        if (!user) {
            return [401, {message: 'Invalid auth token'}];
        }

        return [
            200,
            {
                user: {
                    person:{
                        id: user.person.id,
                        picture: user.person.picture,
                        firstname: user.person.firstname,
                        lastname: user.person.lastname,
                        fullname: user.person.fullname,
                        phone: user.person.phone,
                        email: user.person.email,
                    },
                    username: user.username,
                    password: user.password,
                    locale: user.locale,
                    status: user.status,
                    profile: user.profile,
                    // site: user.site,
                    token: user.token

                    // id: randomId(),
                    // avatar: null,
                    // email,
                    // username: null,
                    // password,
                    // profile: null,
                }
            }
        ];
    } catch (err) {
        console.error('Error: ', err);
        return [500, {message: 'Encountered a server error'}];
    }
});
