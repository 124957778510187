import {ChangeEvent, Fragment, useEffect, useRef, useState} from 'react';

import {
    alpha,
    Avatar,
    Badge,
    Box,
    Button,
    Divider,
    IconButton,
    Tab,
    Tabs,
    Card,
    Popover,
    useTheme,
    styled,
    Tooltip,
    Typography,
    ListItem,
    ListItemAvatar,
    ListItemText,
    List
} from '@mui/material';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import Scrollbar from 'src/components/Scrollbar';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import Text from 'src/components/Text';

import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import Link from '@mui/material/Link';
import {useTranslation} from 'react-i18next';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';

import {CallDispatch, RootState, useDispatch, useSelector} from 'src/store';

import {downloadFile} from 'src/service/file-service';
import {
    generateRoomId,
    getLastMessageByCreator,
    topic
} from 'src/utils/utils';
import useAuth from 'src/hooks/useAuth';
import slice, {
    getLatDiscussion,
    getMessengers, resetStateMessenger,
    setCallAccepted,
    setCallEnded,
    setCallType,
    setIsIncomingCall,
    setUserSelected
} from 'src/slices/messenger';
import {format} from 'date-fns';
import {
    readAppointmentNotification,
    readMessageNotification,
    unreadNotifications,
    deliveredNotifications, updateUnreadNotifications
} from 'src/slices/notifications';
import {
    AppointmentType,
    NotificationType,
    Role
} from '../../../../../models/user';
import VideoCallDialog from '../../../../../content/provider/Messenger/CallVideo';
import {useStompClient, useSubscription} from 'react-stomp-hooks';
import {Appointment} from '../../../../../models/event';
import {useCallContext} from 'src/slices/call';

const BoxComposed = styled(Box)(
    () => `
  position: relative;
`
);

const BoxComposedContent = styled(Box)(
    ({theme}) => `
  position: relative;
  z-index: 7;

  .MuiTypography-root {
      color: ${theme.palette.primary.contrastText};

      & + .MuiTypography-root {
          color: ${alpha(theme.palette.primary.contrastText, 0.7)};
      }
  }
  
`
);

const BoxComposedImage = styled(Box)(
    () => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  filter: grayscale(80%);
  background-size: cover;
  height: 100%;
  width: 100%;
  border-radius: inherit;
`
);

const BoxComposedBg = styled(Box)(
    () => `
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  height: 100%;
  width: 100%;
  border-radius: inherit;
`
);

const TabsWrapper = styled(Tabs)(
    ({theme}) => `
      overflow: visible !important;

      .MuiTabs-scroller {
          overflow: visible !important;
      }

      .MuiButtonBase-root {
          text-transform: uppercase;
          font-size: ${theme.typography.pxToRem(12)};

          &:last-child {
            margin-right: 0;
          }
      }
  `
);

const AvatarSuccess = styled(Avatar)(
    ({theme}) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.success.main};
      width: ${theme.spacing(10)};
      height: ${theme.spacing(10)};
      margin: 0 auto ${theme.spacing(2)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(42)};
      }
`
);

const LabelPrimary = styled(Box)(
    ({theme}) => `
    font-weight: bold;
    border-radius: ${theme.general.borderRadiusSm};
    background: ${theme.colors.primary.main};
    text-transform: uppercase;
    font-size: ${theme.typography.pxToRem(10)};
    padding: ${theme.spacing(0.5, 1.5)};
    color: ${theme.palette.primary.contrastText};
`
);

const DividerVertialPrimary = styled(Box)(
    ({theme}) => `
  height: 60%;
  width: 6px;
  left: -3px;
  border-radius: 50px;
  position: absolute;
  top: 20%;
  background: ${theme.colors.primary.main};
`
);

const DotLegend = styled('span')(
    ({theme}) => `
    border-radius: 22px;
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: ${theme.spacing(0.5)};
`
);

const ListWrapper = styled(List)(
    () => `
    .MuiListItem-root:last-of-type + .MuiDivider-root {
        display: none;
    }
`
);

const IconButtonWrapper = styled(IconButton)(
    ({theme}) => `
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  border-radius: ${theme.general.borderRadiusLg};
`
);

function HeaderNotifications() {
    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const {t}: { t: any } = useTranslation();
    const theme = useTheme();
    const {user} = useSelector((state) => state.auth);
    const {appointmentNotifications: notificationsUnread} = useSelector((state) => state.notifications);
    const {usersOnline, callState,showDialogCall, remoteStream,localStream, room} = useSelector((state) => state.messenger);
    const {messagesNotification} = useSelector((state) => state.notifications);
    const [currentTab, setCurrentTab] = useState<string>(NotificationType.MESSENGER);
    const dispatch = useDispatch();
    const navigateToMessenger = `/teleconsultation/${user?.profile?.role == Role.PRESTATAIRE ? 'provider' : 'configuration'}/messenger`;
    const navigateToAllNotification = `/teleconsultation/${user?.profile?.role == Role.PRESTATAIRE ? 'provider' : 'configuration'}/notifications`;

    const tabs = [
        ...Object.values(NotificationType).map((type) => ({
            id: type,
            name: t(type)
        }))
    ];


    useEffect(() => {
        if (!showDialogCall)
            dispatch(slice.actions.audioRefAction({type: 'call', event: 'stop'}));
    }, [showDialogCall]);


    useSubscription(topic(`leaveRoom/${room}`), async (e) => {
        if (e.body !== user.id){
            dispatch(setIsIncomingCall(false));
            if (remoteStream) {
                remoteStream.getTracks().forEach((track) => track.stop());
            }
            if (localStream) {
                localStream.getTracks().forEach((track) => track.stop());
            }
            dispatch(slice.actions.setDialogCall(false));
            dispatch(slice.actions.setUserSelected(null));
            dispatch(slice.actions.resetStateMessenger());
            dispatch(slice.actions.setRemoteStream(null));
            dispatch(slice.actions.setRemoteDevice(null));
            dispatch(slice.actions.setCallState("new"));
            dispatch(slice.actions.setRoom(null));
        }
    });

    useSubscription(topic(`users-online/${user?.id}`), (e) => {
        const n: string[] = JSON.parse(e.body);
        if (n) {
            dispatch(slice.actions.setUsersOnline({users: n}));
        }
    });

    useSubscription(topic(`notification/${user?.id}`), (e) => {
        const n = JSON.parse(e.body);
        if (n) {
            dispatch(
                slice.actions.audioRefAction({type: 'notification', event: 'play'})
            );
            dispatch(deliveredNotifications(n.id));
            dispatch(updateUnreadNotifications(n));
            if (n.type == NotificationType.MESSENGER)
                dispatch(getLatDiscussion(usersOnline, user.id));
        }
    });

    useSubscription(topic(`notification/call/${user?.id}`), (message) => {
        const appt: Appointment = JSON.parse(message.body);
        if (appt && callState !== "answered") {
            dispatch(slice.actions.audioRefAction({type: 'call', event: 'play'}));
            dispatch(slice.actions.setRoom(appt.room));
            dispatch(slice.actions.setCaller(false));
            dispatch(slice.actions.setIsIncomingCall(true));
            dispatch(slice.actions.setCallState("incoming"));
            dispatch(resetStateMessenger())
            dispatch(
                slice.actions.setUserSelected({
                    person: appt.personCreator,
                    id: appt.creator
                })
            );
            dispatch(setCallType(appt.appointmentType == "video" ? "video" : "audio"));
            dispatch(slice.actions.setRemoteDevice(appt.deviceID));
            dispatch(slice.actions.setDialogCall(true));
        }
    });

    useSubscription(topic('new-user-online'), (e) => {
        dispatch(slice.actions.setUsersOnline({user: e.body}));
    });
    useSubscription(topic('old-user-online'), (e) => {
        dispatch(slice.actions.removeUsersOnline({user: e.body}));
    });

    const handleTabsChange = (_event: ChangeEvent<{}>, value: string): void => {
        setCurrentTab(value);
    };

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleUserSelected = (obj) => {
        dispatch(setUserSelected(obj?.creator));
        dispatch(readMessageNotification(obj?.creator.id));
        handleClose();
    };

    const handleNotificationSelected = (obj) => {
        if (obj?.type === 'messenger') {
            dispatch(readMessageNotification(obj?.message));
        } else {
            dispatch(readAppointmentNotification([obj?.appointment]));
        }
        handleClose();
    };


    return (
        <>
            <Tooltip arrow title={t('Notifications')}>
                <Badge
                    ref={ref}
                    onClick={handleOpen}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    sx={{
                        mx: 2,
                        '.MuiBadge-badge': {
                            background: theme.colors.success.main,
                            animation: 'pulse 1s infinite',
                            color: 'white',
                            transition: `${theme.transitions.create(['all'])}`
                        }
                    }}
                    badgeContent={
                        messagesNotification?.length + notificationsUnread?.length
                    }
                    overlap="circular"
                    showZero={false}
                >
                    <IconButtonWrapper
                        sx={{
                            background: alpha(theme.colors.primary.main, 0.1),
                            transition: `${theme.transitions.create(['background'])}`,
                            color: theme.colors.primary.main,

                            '&:hover': {
                                background: alpha(theme.colors.primary.main, 0.2)
                            }
                        }}
                        color="primary"
                    >
                        <NotificationsActiveTwoToneIcon fontSize="small"/>
                    </IconButtonWrapper>
                </Badge>
            </Tooltip>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box minWidth={440} maxWidth={440} p={2}>
                    <BoxComposed
                        mb={2}
                        sx={{
                            borderRadius: `${theme.general.borderRadius}`,
                            background: `${theme.colors.gradients.black1}`
                        }}
                    >
                        <BoxComposedBg
                            sx={{
                                opacity: 0.3,
                                background: `${theme.colors.gradients.green2}`
                            }}
                        />
                        <BoxComposedImage
                            sx={{
                                opacity: 0.2,
                                backgroundImage:
                                    'url("/static/images/placeholders/covers/1.jpg")'
                            }}
                        />
                        <BoxComposedContent py={3}>
                            <Typography
                                textAlign="center"
                                sx={{
                                    pb: 0.5
                                }}
                                variant="h4"
                            >
                                {t('Notifications')}
                            </Typography>
                            {
                                <Typography textAlign="center" variant="subtitle2">
                                    {t('You have')}{' '}
                                    <Text color="success">
                                        <b>
                                            {messagesNotification?.length +
                                                notificationsUnread?.length}
                                        </b>
                                    </Text>{' '}
                                    {t('Notifications')}
                                </Typography>
                            }
                        </BoxComposedContent>
                    </BoxComposed>
                    <TabsWrapper
                        centered
                        onChange={handleTabsChange}
                        value={currentTab}
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                    >
                        {tabs.map((tab, i) => (
                            <Tab key={tab.id} value={tab.id} label={tab.name}/>
                        ))}
                    </TabsWrapper>
                </Box>
                <Divider/>
                {tabs.map((tab, i) => {
                    if (tab.id === NotificationType.MESSENGER && tab.id == currentTab) {
                        return (
                            <Box
                                sx={{
                                    height: 220
                                }}
                                key={i}
                            >
                                <Scrollbar>
                                    <ListWrapper disablePadding>
                                        {messagesNotification?.length > 0 &&
                                            Object?.values(
                                                getLastMessageByCreator(messagesNotification)
                                            )?.map((obj: any, i) => {
                                                if (obj?.toUser === user.id) {
                                                    return (
                                                        <Fragment key={i}>
                                                            <ListItem
                                                                key={i}
                                                                onClick={() => handleUserSelected(obj)}
                                                                component={RouterLink}
                                                                to={navigateToMessenger}
                                                                sx={{
                                                                    py: 1.5,
                                                                    '&:hover': {
                                                                        background: `${theme.colors.alpha.black[5]}`
                                                                    }
                                                                }}
                                                                secondaryAction={
                                                                    <Typography
                                                                        sx={{
                                                                            alignSelf: 'center',
                                                                            padding: `${theme.spacing(0.5, 1.5)}`,
                                                                            backgroundColor: `${theme.colors.success.lighter}`,
                                                                            textTransform: 'uppercase',
                                                                            fontSize: `${theme.typography.pxToRem(
                                                                                13
                                                                            )}`,
                                                                            '&:hover': {
                                                                                backgroundColor: `${theme.colors.success.main}`,
                                                                                color: `${theme.palette.getContrastText(
                                                                                    theme.colors.secondary.main
                                                                                )}`
                                                                            }
                                                                        }}
                                                                    >
                                                                        <Text color="success">
                                                                            {obj?.messageCount}
                                                                        </Text>
                                                                    </Typography>
                                                                }
                                                            >
                                                                <ListItemAvatar
                                                                    sx={{
                                                                        minWidth: 38,
                                                                        mr: 1
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        sx={{
                                                                            width: 38,
                                                                            height: 38
                                                                        }}
                                                                        alt={obj?.creator?.person?.firstname}
                                                                        src={downloadFile(
                                                                            obj?.creator?.person?.image?.path
                                                                        )}
                                                                    />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    sx={{
                                                                        flexGrow: 0,
                                                                        maxWidth: '80%',
                                                                        // flexBasis: '50%'
                                                                    }}
                                                                    primaryTypographyProps={{
                                                                        color: 'textPrimary',
                                                                        noWrap: true
                                                                    }}
                                                                    secondaryTypographyProps={{
                                                                        color: 'textSecondary',
                                                                        noWrap: true
                                                                    }}
                                                                    primary={
                                                                        <Typography
                                                                            sx={{
                                                                                pb: 0.6
                                                                            }}
                                                                            color="text.primary"
                                                                            variant="h5"
                                                                        >
                                                                            {`${obj?.creator?.person?.fullname}`}
                                                                        </Typography>
                                                                    }
                                                                    secondary={
                                                                        <Box display="flex" alignItems="flex-start">
                                                                            <DotLegend
                                                                                style={{
                                                                                    background: `${theme.colors.success.main}`,
                                                                                    marginBottom: 4
                                                                                }}
                                                                            />
                                                                            <Typography
                                                                                sx={{
                                                                                    fontSize: `${theme.typography.pxToRem(
                                                                                        13
                                                                                    )}`,
                                                                                    lineHeight: 1,
                                                                                    ml: 1
                                                                                }}
                                                                                variant="body1"
                                                                            >
                                                                                <Text>{obj?.lastMessage?.body}</Text>
                                                                                <Box sx={{
                                                                                    mt: 0.8,
                                                                                    fontStyle: 'italic',
                                                                                    fontSize: `${theme.typography.pxToRem(
                                                                                        10
                                                                                    )}`
                                                                                }}>
                                                                                    <Text>
                                                                                        {format(
                                                                                            new Date(obj?.lastMessage?.created),
                                                                                            'MMM dd yyyy hh:mm:ss'
                                                                                        )}
                                                                                    </Text>
                                                                                </Box>
                                                                            </Typography>
                                                                        </Box>
                                                                    }
                                                                />
                                                            </ListItem>
                                                            <Divider/>
                                                        </Fragment>
                                                    );
                                                }
                                            })}
                                    </ListWrapper>
                                </Scrollbar>
                            </Box>
                        )
                            ;
                    } else if (tab.id == currentTab) {
                        return (
                            <Box
                                sx={{
                                    height: 220
                                }}
                                key={i}
                            >
                                <Scrollbar>
                                    {notificationsUnread?.map((noti, key) => (
                                        <Box key={key} p={1}>
                                            <Card
                                                sx={{
                                                    overflow: 'visible',
                                                    position: 'relative',
                                                    p: 2
                                                }}
                                                variant="outlined"
                                            >
                                                <DividerVertialPrimary/>
                                                <Link
                                                    component={RouterLink}
                                                    onClick={() => handleNotificationSelected(noti)}
                                                    to={'/teleconsultation/provider/dashboard'}
                                                >
                                                    <Typography
                                                        color="text.primary"
                                                        variant="h4"
                                                        fontWeight="normal"
                                                    >
                                                        {t(noti?.body)}
                                                    </Typography>
                                                </Link>
                                                <Box mt={1.5} display="flex" alignItems="center">
                                                    <LabelPrimary>{t(noti?.type)}</LabelPrimary>
                                                    <Text flex color="error">
                                                        <AccessTimeTwoToneIcon
                                                            sx={{
                                                                ml: 1,
                                                                mr: 0.5
                                                            }}
                                                            fontSize="small"
                                                        />
                                                        {format(
                                                            new Date(noti?.created),
                                                            'MMM dd yyyy hh:mm:ss'
                                                        )}
                                                    </Text>
                                                </Box>
                                            </Card>
                                        </Box>
                                    ))}
                                </Scrollbar>
                            </Box>
                        );
                    }
                })}
                <Divider/>
                <Box
                    sx={{
                        background: `${theme.colors.alpha.black[5]}`,
                        textAlign: 'center'
                    }}
                    p={2}
                >
                    <Button
                        component={RouterLink}
                        to={navigateToAllNotification}
                        size="small"
                        color="primary"
                        variant="contained"
                        endIcon={<ArrowForwardTwoToneIcon/>}
                    >
                        {t('View all Notifications')}
                    </Button>
                </Box>
            </Popover>
            {
                showDialogCall && <VideoCallDialog/>
            }
        </>
    );
}

export default HeaderNotifications;
