import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Consultation } from "src/models/consultation";
import { getAllConsultations, saveConsultation } from "src/service/consultation-service";
import { AppThunk } from "src/store";


interface ConsultationState {
    consultation: Consultation,
}

const initialState: ConsultationState = {
    consultation: null
}

const slice = createSlice({
    name: 'consultation',
    initialState,
    reducers: {
        getConsult(state: ConsultationState, action: PayloadAction<any>) {
            state.consultation = action.payload;
        },
        addConsult(state: ConsultationState, action: PayloadAction<any>) {
            state.consultation = action.payload;
        },
        setConsultation(state: ConsultationState, action: PayloadAction<any>) {
            state.consultation = action.payload;
        },
    }
});

export const getConsultations = (): AppThunk =>
    async (dispatch) => {
        const response = await getAllConsultations();
        dispatch(slice.actions.getConsult(response.data));
    }

export const createConsultation = (data: Consultation): AppThunk =>
    async (dispatch) => {
        const response = await saveConsultation(data);
        dispatch(slice.actions.addConsult(response.data));
    }

export const setConsultation = (data: Consultation): AppThunk =>
    async (dispatch) => {
        dispatch(slice.actions.setConsultation(data));
    }


export const reducer = slice.reducer;
export default slice;
