import type {ReactNode} from 'react';
import {Role} from 'src/models/user';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import LocalHospitalOutlined from '@mui/icons-material/LocalHospitalOutlined';
import ChatIcon from '@mui/icons-material/Chat';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined'
import AssignmentIndOutlined from '@mui/icons-material/AssignmentIndOutlined'

export interface MenuItem {
    link?: string;
    icon?: ReactNode;
    badge?: string;
    badgeTooltip?: string;

    items?: MenuItem[];
    name: string;
}

export interface MenuItems {
    items: MenuItem[];
    heading?: string;
    permission: string[];
}


const menuItems: MenuItems[] = [
        {
            permission: [Role.SUPER, Role.PRESTATAIRE],
            items: [
                {
                    name: 'Provider',
                    icon: AssignmentIndOutlined,
                    link: '/teleconsultation/provider',
                    items: [
                        {
                            name: 'Home',
                            link: '/teleconsultation/provider/dashboard',
                            icon: DashboardOutlined
                        },
                        {
                            name: 'Agenda',
                            link: '/teleconsultation/provider/agenda',
                            icon: ViewListOutlinedIcon
                        },
                        {
                            name: 'Messenger',
                            link: '/teleconsultation/provider/messenger',
                            icon: ChatIcon
                        }
                    ]
                }
            ]
        },
        {
            permission: [Role.ADMIN, Role.SUPER, Role.OFFICIER],
            items: [
                {
                    name: 'Manage Officier',
                    icon: TopicOutlinedIcon,
                    link: '/teleconsultation/officier',
                    items: [
                        {
                            name: 'Counseling',
                            icon: LightbulbOutlinedIcon,
                            link: '/teleconsultation/officier/counseling',
                            items: [
                                {
                                    name: 'Dashboard',
                                    link: '/teleconsultation/officier/counseling/dashboard'
                                },
                                // {
                                //     name: 'Form',
                                //     link: '/teleconsultation/officier/counseling/form'
                                // },
                                // {
                                //     name: 'List',
                                //     link: '/teleconsultation/officier/counseling/list'
                                // },   
                            ]
                        }
                    ]
                },
            ]
        },
        {
            permission: [Role.ADMIN, Role.SUPER],
            items: [
                {
                    name: 'Administration',
                    icon: SettingsOutlinedIcon,
                    link: '',
                    items: [
                        {
                            name: 'Manage Users',
                            link: '/teleconsultation/configuration/users',
                            icon: ManageAccountsOutlinedIcon
                        },
                        {
                            name: 'Manage Sites',
                            link: '/teleconsultation/configuration/sites',
                            icon: LocalHospitalOutlined
                        },
                        {
                            name: 'Manage Specialities',
                            link: '/teleconsultation/configuration/specialities',
                            icon: LocalHospitalOutlined
                        },
                        {
                            name: 'Messenger',
                            link: '/teleconsultation/configuration/messenger',
                            icon: ChatIcon
                        }
                    ]
                },
            ]
        },
    ]
;

export default menuItems;
