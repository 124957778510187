import {Suspense, lazy, Fragment} from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';
import {RouteObject} from "react-router";
import ExtendedSidebarLayout from "../layouts/ExtendedSidebarLayout";
import ProviderUser from "../components/Authenticated";
import RecipientAuthenticated from "../components/Authenticated/recipient";
import {Navigate} from "react-router-dom";
import {Box} from "@mui/material";

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader/>}>
            <Component {...props} />
        </Suspense>
    );
const Home = Loader(lazy(() => import('src/content/recipient')));


const recipientRoutes: RouteObject[] = [
        {
            path: '',
            element: <Navigate to="dashboard" replace/>
        },
        {
            path: 'dashboard',
            element: <RecipientAuthenticated>
                <ExtendedSidebarLayout/>
                <Home/>
            </RecipientAuthenticated>
            ,
        }
    ]
;

export default recipientRoutes;
