import * as Yup from 'yup';
import type { FC } from 'react';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import {
  Box,
  Button,
  TextField,
  Link,
  CircularProgress,
  Stack,
  InputAdornment,
  IconButton,
  Alert
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { PersonOutlineOutlined, LockOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {login} from "../../../../service/account-service";

const LoginJWT: FC = () => {
  const auth = useAuth();

  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [msgError, setMsgError] = useState("");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .max(255)
          .required(t('The username field is required')),
        password: Yup.string()
          .max(255)
          .required(t('The password field is required')),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting }
      ): Promise<void> => {
        try {
          const user = await login({username:values.username, password: values.password});
          await auth.signIn(user);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setMsgError(err.message);
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            alignItems="stretch"
            spacing={0}
          >
            <Box sx={{
              pt: 4,
              pr: 2,
            }}
              display="flex" alignItems="start">
              <img
                style={{ height: 100 }}
                src="/static/images/placeholders/logo/serovieLogo.png"
                alt="..."
              />
            </Box>
            <Box>
              <TextField
                error={Boolean(touched.username && errors.username)}
                fullWidth
                margin="normal"
                autoFocus
                  helperText={touched.username && errors.username}
                label={t('Username')}
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.username}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineOutlined color='primary' />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                margin="normal"
                helperText={touched.password && errors.password}
                label={t('Password')}
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        color='secondary'
                      >
                        {!showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined color='primary' />
                    </InputAdornment>
                  )
                }}
              />
              <Box
                alignItems="center"
                display={{ xs: 'block', md: 'flex' }}
                justifyContent="space-between"
              >

                <Link component={RouterLink} to="/account/recover-password">
                  <b>{t('Mot de passe oublié?')}</b>
                </Link>
              </Box>
              <Box
                alignItems="center"
                display={{ xs: 'block', md: 'flex' }}
                justifyContent="space-between"
              >
                {errors?.submit && (<Alert severity="error">
                  {t( msgError
                    // 'Username or password incorrect'
                  )}
                </Alert>)
                }
              </Box>
              <Box
                sx={{
                  textAlign: 'right'
                }}
              >
                <Button
                  sx={{
                    mt: 5
                  }}
                  color="primary"
                  startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
                  disabled={isSubmitting}
                  type="submit"
                  // fullWidth
                  size="large"
                  variant="text"
                  endIcon={<ArrowForwardTwoToneIcon />}
                >
                  {t('Sign in')}
                </Button>
              </Box>
            </Box>
          </Stack>
        </form>
      )}
    </Formik>
  );
};

export default LoginJWT;
