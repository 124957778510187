import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import enJSON from './translations/en';
import htJSON from './translations/ht';
import frJSON from './translations/fr';

const resources = {
  en: { translation: enJSON },
  ht: { translation: htJSON },
  fr: { translation: frJSON },
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    lng: 'fr',
    fallbackLng: 'fr',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
